import React, { useRef, useState,useEffect } from 'react';
import dayjs from 'dayjs';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {
    AutoComplete,
    Col, Row, 
    Button,
    Image,
    Tooltip,
    Space,
    Table,
    Tag,
    Cascader,
    Checkbox,
    Divider,
    DatePicker,
    TimePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Select,
    Spin,
    Switch,
    TreeSelect,
    Upload,
    Typography,
  } from 'antd';
import {Header,confirmDialog,oneButtonConfirmDialog,confirmDialog2} from '../Common/commonui.tsx'
import {emptyCache,CallAPI,GetData,ConsoleLog,TolocalDateString,webUrl} from '../Common/commonfunction.tsx';
import {createBrowserRouter,RouterProvider,useNavigate } from "react-router-dom";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimeField } from '@mui/x-date-pickers/TimeField';

interface SelectOption {
    label: string;
    value: string;
  }

interface FlightInfoObject {
    AirportCode: string;
    FlightType: string;
    FlightNo: string;
    FlightDate: string;
    FlightTime: string;
    Airline: string;
    Location: string;
    Hall: string;
    Status: string;
    LastUpdate: string;
    key: string;
}

interface DriverObject {
    key: string;
    DriverID: string;
    Name: string;
    Password: string;
    DriverTelNo: string;
    Status: string;
    UUID: string;
    Remarks: string;
    CreatedBy: string;
    CreatedDatetime: string;
    UpdatedBy: string;
    UpdatedDatetime: string;
}


function App() {
    const { Text, Link } = Typography;
    const { TextArea } = Input;
    let navigate = useNavigate();

    const [loading,SetLoading] = useState(true);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);//localStorage.getItem('isOpenPhoto')==="true"
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [DriverFilterList,SetDriverFilterList] = useState<SelectOption[]>([]);
    const [VehicleList,SetVehicleList] = useState<SelectOption[]>([]);
    const [VehicleFilterList,SetVehicleFilterList] = useState<SelectOption[]>([]);
    const [FlightNoList,SetFlightNoList] = useState<SelectOption[]>([]);
    const [FlightInfoList,SetFlightInfoList] = useState<FlightInfoObject[]>([]);
    const [TunnelList,SetTunnelList] = useState([]);

    const [DriverDetailList,SetDriverDetailList] = useState([]);
    const [DriverFilterDetailList,SetDriverFilterDetailList] = useState<any[]>([]);
    const [IsErrorDialog,SetIsErrorDialog] = useState(false);

    const [OrderDetailPageStatus,SetOrderDetailPageStatus] = useState(
        { OrderNo:localStorage.getItem('DCHOrderNo'),
            EformOrderNo:localStorage.getItem('DCHEformOrderNo'),
            CarType:localStorage.getItem('DCHCarType'),
            Journey:localStorage.getItem('DCHJourney'),
            CustomerType:localStorage.getItem('DCHCustomerType'),
            CustomerName:localStorage.getItem('DCHCustomerName'),
            Address:localStorage.getItem('DCHAddress'),
            ContactName1:localStorage.getItem('DCHContactName1'),
            ContactTelNo1:localStorage.getItem('DCHContactTelNo1'),
            ContactTelNo2:localStorage.getItem('DCHContactTelNo2'),
            Title:localStorage.getItem('DCHTitle'),
            PassengerName:localStorage.getItem('DCHPassengerName'),
            PassengerTelNo:localStorage.getItem('DCHPassengerTelNo'),
            EstimatedOrderDuration:localStorage.getItem('DCHEstimatedOrderDuration'),
            Pickup:localStorage.getItem('DCHActualPickup'),
            Destination:localStorage.getItem('DCHActualDestination'),
            StopOver1:localStorage.getItem('DCHActualStopOver1'),
            StopOver2:localStorage.getItem('DCHActualStopOver2'),
            StopOver3:localStorage.getItem('DCHActualStopOver3'),
            FlightNo:localStorage.getItem('DCHActualFlightNo'),
            FlightSchedule:localStorage.getItem('DCHFlightSchedule'),
            FlightStatus:localStorage.getItem('DCHFlightStatus'),
            NoOfPassenger:localStorage.getItem('DCHNoOfPassenger'),
            NoOfLuggage:localStorage.getItem('DCHNoOfLuggage'),
            FirstReminder:localStorage.getItem('DCHFirstReminder'),
            SpecialInstructions:localStorage.getItem('DCHSpecialInstructions'),
            HireRequest:localStorage.getItem('DCHHireRequest'),
            OrderPickUpDatetime:localStorage.getItem('DCHActualOrderPickUpDatetime'),
            OrderCharge:localStorage.getItem('DCHOrderCharge'),
            AdditionalCharge:localStorage.getItem('DCHAdditionalCharge'),
            ParkingCharge:localStorage.getItem('DCHParkingCharge'),
            TunnelCharge:localStorage.getItem('DCHTunnelCharge'),
            TunnelString:localStorage.getItem('DCHTunnelString'),
            TunnelChargeList:localStorage.getItem('DCHTunnelChargeList'),
            AssignVehicleID:localStorage.getItem('DCHAssignVehicleID'),
            AssignDriverID:localStorage.getItem('DCHAssignDriverID'),
            DriverName:localStorage.getItem('DCHDriverName'),
            DriverMobile:localStorage.getItem('DCHDriverMobile'),
            ServiceProvider:localStorage.getItem('DCHServiceProvider'),
            VendorName:localStorage.getItem('DCHVendorName'),
            VendorDriver:localStorage.getItem('DCHVendorDriver'),
            Status:localStorage.getItem('DCHStatus'),
            HavePhoto:localStorage.getItem('DCHHavePhoto'),
            HaveMeetNGreet:localStorage.getItem('DCHHaveMeetNGreet'),
            Remarks:localStorage.getItem('DCHRemarks'),
            ReadyToUpload:localStorage.getItem('DCHReadyToUpload'),
            CreatedBy:localStorage.getItem('DCHCreatedBy'),
            CreatedDatetime:localStorage.getItem('DCHCreatedDatetime'),
            UpdatedBy:localStorage.getItem('DCHUpdatedBy'),
            UpdatedDatetime:localStorage.getItem('DCHUpdatedDatetime'),
            DriverStartWaitDatetime:localStorage.getItem('DCHDriverStartWaitDatetime'),
            DriverWaitDuration:localStorage.getItem('DCHDriverWaitDuration'),
            DriverPickUpDatetime:localStorage.getItem('DCHDriverPickUpDatetime'),
            DriverDropOffDatetime:localStorage.getItem('DCHDriverDropOffDatetime'),
            OrderLock:localStorage.getItem('DCHOrderLock'),
            SMSEnable:localStorage.getItem('DCHSMSEnable'),
        }
    );
    const [Journey,SetJourney] = useState(localStorage.getItem('DCHJourney'));
    const [EstimatedOrderDuration,SetEstimatedOrderDuration] = useState(localStorage.getItem('DCHEstimatedOrderDuration'));
    const [BookingDatetime,SetBookingDatetime] = useState(localStorage.getItem('DCHBookingDatetime'));
    const [ActualOrderPickUpDate,SetActualOrderPickUpDate] = useState(localStorage.getItem('DCHActualOrderPickUpDatetime')!.substring(0,10));
    const [ActualOrderPickUpTime,SetActualOrderPickUpTime] = useState(localStorage.getItem('DCHActualOrderPickUpDatetime')!.substring(11,16));
    const [ActualFlightNo,SetActualFlightNo] = useState(localStorage.getItem('DCHActualFlightNo'));
    const [FlightNo,SetFlightNo] = useState(localStorage.getItem('DCHFlightNo'));
    const [FlightSchedule,SetFlightSchedule] = useState(localStorage.getItem('DCHFlightSchedule'));
    const [FlightStatus,SetFlightStatus] = useState(localStorage.getItem('DCHFlightStatus'));

    const [OrderPickUpDate,SetOrderPickUpDate] = useState(localStorage.getItem('DCHOrderPickUpDatetime')!.substring(0,10));
    const [OrderPickUpTime,SetOrderPickUpTime] = useState(localStorage.getItem('DCHOrderPickUpDatetime')!.substring(11,16));

    const [Pickup,SetPickup] = useState(localStorage.getItem('DCHPickup'));
    const [Destination,SetDestination] = useState(localStorage.getItem('DCHDestination'));
    const [StopOver1,SetStopOver1] = useState(localStorage.getItem('DCHStopOver1'));
    const [StopOver2,SetStopOver2] = useState(localStorage.getItem('DCHStopOver2'));
    const [StopOver3,SetStopOver3] = useState(localStorage.getItem('DCHStopOver3'));

    const [DriverDropOffDatetime,SetDriverDropOffDatetime] = useState(localStorage.getItem('DCHDriverDropOffDatetime'));
    const [DriverDropOffDatetime2,SetDriverDropOffDatetime2] = useState(localStorage.getItem('DCHDriverDropOffDatetime2'));
    const [DriverPickUpDatetime,SetDriverPickUpDatetime] = useState(localStorage.getItem('DCHDriverPickUpDatetime'));
    const [DriverPickUpDatetime2,SetDriverPickUpDatetime2] = useState(localStorage.getItem('DCHDriverPickUpDatetime2'));
    const [DriverStartWaitDatetime,SetDriverStartWaitDatetime] = useState(localStorage.getItem('DCHDriverStartWaitDatetime'));
    const [DriverStartWaitDatetime2,SetDriverStartWaitDatetime2] = useState(localStorage.getItem('DCHDriverStartWaitDatetime2'));
    const [DriverWaitDuration,SetDriverWaitDuration] = useState(localStorage.getItem('DCHDriverWaitDuration'));
    const [DriverWaitDurationM,SetDriverWaitDurationM] = useState((parseInt(localStorage.getItem('DCHDriverWaitDuration')!) - parseInt(localStorage.getItem('DCHDriverWaitDuration')!)%60)/60);
    const [DriverWaitDurationS,SetDriverWaitDurationS] = useState(parseInt(DriverWaitDuration!)%60);
    const [DriverWaitDuration2,SetDriverWaitDuration2] = useState(localStorage.getItem('DCHDriverWaitDuration2'));
    
    const [VehicleID,SetVehicleID] = useState(localStorage.getItem('DCHAssignVehicleID'));
    const [DriverID,SetDriverID] = useState(localStorage.getItem('DCHAssignDriverID'));
    const [DriverName,SetDriverName] = useState(localStorage.getItem('DCHDriverName'));
    const [DriverMobile,SetDriverMobile] = useState(localStorage.getItem('DCHDriverMobile'));

    const [Status,SetStatus] = useState(localStorage.getItem('DCHStatus'));

    const updaloadFilePhoto01 = useRef<HTMLInputElement>(null)
    const updaloadFilePhoto02 = useRef<HTMLInputElement>(null)
    const updaloadFilePhoto03 = useRef<HTMLInputElement>(null)
    const updaloadFilePhoto04 = useRef<HTMLInputElement>(null)
    const updaloadFilePhoto05 = useRef<HTMLInputElement>(null)
    const updaloadFilePhoto06 = useRef<HTMLInputElement>(null)
    const updaloadFilePhoto07 = useRef<HTMLInputElement>(null)
    const updaloadFilePhoto08 = useRef<HTMLInputElement>(null)
    const updaloadFilePhoto09 = useRef<HTMLInputElement>(null)
    const updaloadFilePhoto10 = useRef<HTMLInputElement>(null)
    const [reloadCount,SetReloadCount] = useState({"P1":0,"P2":0,"P3":0,"P4":0,"P5":0,"P6":0,"P7":0,"P8":0,"P9":0,"P10":0})
    const [tempPhotoData,SetTempPhotoData] = useState("");
    const [photoList,SetPhotoList] = useState([{Photo1:"",Photo2:"",Photo3:"",Photo4:"",Photo5:"",Photo6:"",Photo7:"",Photo8:"",Photo9:"",Photo10:""}]);

    const [TunnelTimesList,SetTunnelTimesList] = useState<any>({});
    const [TunnelPriceList,SetTunnelPriceList] = useState<any>({});
    // const [TunnelTimes01,SetTunnelTimes01] = useState(localStorage.getItem('DCHTunnelTimes01'));
    // const [TunnelTimes02,SetTunnelTimes02] = useState(localStorage.getItem('DCHTunnelTimes02'));
    // const [TunnelTimes03,SetTunnelTimes03] = useState(localStorage.getItem('DCHTunnelTimes03'));
    // const [TunnelTimes04,SetTunnelTimes04] = useState(localStorage.getItem('DCHTunnelTimes04'));
    // const [TunnelTimes05,SetTunnelTimes05] = useState(localStorage.getItem('DCHTunnelTimes05'));
    // const [TunnelTimes06,SetTunnelTimes06] = useState(localStorage.getItem('DCHTunnelTimes06'));
    // const [TunnelTimes07,SetTunnelTimes07] = useState(localStorage.getItem('DCHTunnelTimes07'));
    // const [TunnelTimes08,SetTunnelTimes08] = useState(localStorage.getItem('DCHTunnelTimes08'));
    // const [TunnelTimes09,SetTunnelTimes09] = useState(localStorage.getItem('DCHTunnelTimes09'));
    // const [TunnelTimes10,SetTunnelTimes10] = useState(localStorage.getItem('DCHTunnelTimes10'));
    // const [TunnelTimes11,SetTunnelTimes11] = useState(localStorage.getItem('DCHTunnelTimes11'));
    // const [TunnelTimes12,SetTunnelTimes12] = useState(localStorage.getItem('DCHTunnelTimes12'));
    const PassengerNameRerf = useRef(null)

    const [orderStatusReferenceRawList,SetOrderStatusReferenceRawList] = useState(
        [
          {"StatusName":"Acknowledge","DisplayName":"Acknowledge","Type":"In-Progress","key":0},
          {"StatusName":"Cancel","DisplayName":"Cancelled","Type":"Cancel","key":1},
          {"StatusName":"CancelUploaded","DisplayName":"Cancelled Uploaded","Type":"Uploaded","key":2},
          {"StatusName":"CancelUploadFail","DisplayName":"Cancelled Upload Fail","Type":"Uploaded","key":3},
          {"StatusName":"Confirm","DisplayName":"Confirm","Type":"Outstanding","key":4},
          {"StatusName":"Dispatched","DisplayName":"Dispatched","Type":"In-Progress","key":5},
          {"StatusName":"Dropoff","DisplayName":"Completed","Type":"Complete","key":6},
          {"StatusName":"DropoffUploaded","DisplayName":"Completed Uploaded","Type":"Uploaded","key":7},
          {"StatusName":"DropoffUploadFail","DisplayName":"Completed Upload Fail","Type":"Uploaded","key":8},
          {"StatusName":"Noshow","DisplayName":"No Show","Type":"Noshow","key":9},
          {"StatusName":"NoshowUploaded","DisplayName":"No Show Uploaded","Type":"Uploaded","key":10},
          {"StatusName":"NoshowUploadFail","DisplayName":"No Show Upload Fail","Type":"Uploaded","key":11},
          {"StatusName":"NotAvailable","DisplayName":"Not Available","Type":"Complete","key":12},
          {"StatusName":"Open","DisplayName":"Open","Type":"Outstanding","key":13},
          {"StatusName":"Pickup","DisplayName":"Pick-up","Type":"In-Progress","key":14},
          {"StatusName":"TBC","DisplayName":"TBC","Type":"In-Progress","key":15},
          {"StatusName":"Waiting","DisplayName":"Waiting","Type":"In-Progress","key":16}
        ]
      )
      const [orderStatusReferenceIndexMap,SetOrderStatusReferenceIndexMap] = useState({})
      const [ServiceProviderList,SetServiceProviderList] = useState([
            {label:"",value:""},
            {label:"DCH",value:"DCH"},
            {label:"Consignment",value:"Consignment"},
        ])
    const [ServiceVenderList,SetServiceVenderList] = useState<SelectOption[]>([])
    const [ServiceVenderListfiltered,SetServiceVenderListfiltered] = useState<SelectOption[]>([])
    /*
    {
        "Acknowledge": "Acknowledge",
        "Cancel": "Cancelled",
        "CancelUploaded": "Cancelled Uploaded",
        "CancelUploadFail": "Cancelled Upload Fail",
        "Confirm": "Confirm",
        "Dispatched": "Dispatched",
        "Dropoff": "Completed",
        "DropoffUploaded": "Completed Uploaded",
        "DropoffUploadFail": "Completed Upload Fail",
        "Noshow": "No Show",
        "NoshowUploaded": "No Show Uploaded",
        "NoshowUploadFail": "No Show Upload Fail",
        "NotAvailable": "Not Available",
        "Open": "Open",
        "Pickup": "Pick-up",
        "TBC": "TBC",
        "Waiting": "Waiting"
    }
    */
      const GetOrderStatusReference = () =>{
        var para0 = "";
        var para1 = "action=GetOrderStatusReference";
        var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
        var para3 = para1 + para2;
        //ConsoleLog(para3)
        ConsoleLog("GetOrderStatusReference")
        GetData(para3,
          data => {
            if(data["result"] === "200"){
              if(data["itemObject"] !== "{}"){
                var orderListFromApi = JSON.parse(data["itemObject"])
                var orderListIndex = {}
                for(var j=0;j<orderListFromApi.length;j++){
                  orderListIndex[orderListFromApi[j]["StatusName"]] = orderListFromApi[j]["DisplayName"]
                }
                //ConsoleLog(orderListIndex)
                SetOrderStatusReferenceRawList(orderListFromApi)
                SetOrderStatusReferenceIndexMap(orderListIndex)
                GetFlightSchedule()
              }else{

              }
            }else{
              if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
              }
            }
            SetLoading(false)
          },
          ()=>{
            if(!IsErrorDialog){
              SetIsErrorDialog(true)
              oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
            }
            SetLoading(false);
          }
        )
      }
/*
{\"AirportCode\":\"HKG\",
\"FlightType\":\"Arrival\",
\"FlightNo\":\"3U 3959\",
\"FlightDate\":\"2023-08-01\",
\"FlightTime\":\"17:40:00\",
\"Airline\":\"CSC\",
\"Location\":\"TFU\",
\"Hall\":\"A\",
\"Status\":\"Est at 17:09\",
\"LastUpdate\":\"2023-08-01 15:33:02\",
\"key\":1},
*/
    const GetFlightSchedule = () =>{
        var para0 = "";
        var para1 = "action=GetFlightSchedule";
        var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
        var para3 = para1 + para2;
        //ConsoleLog(para3)
        ConsoleLog("GetFlightSchedule")
        GetData(para3,
            data => {
            //ConsoleLog(data)
            if(data["result"] === "200"){
              var list = JSON.parse(data["itemObject"])
              var selectItemList1:SelectOption[] = []
              var selectItemList2:FlightInfoObject[] = []
              for(var k = 0;k<list.length;k++){
                selectItemList1.push({label: list[k]['FlightNo'].replace(" ",""), value: `${k}`})
                selectItemList2.push({
                    AirportCode: list[k]['AirportCode'],
                    FlightType: list[k]['FlightType'],
                    FlightNo: list[k]['FlightNo'].replace(" ",""),
                    FlightDate: list[k]['FlightDate'],
                    FlightTime: list[k]['FlightTime'],
                    Airline: list[k]['Airline'],
                    Location: list[k]['Location'],
                    Hall: list[k]['Hall'],
                    Status: list[k]['Status'],
                    LastUpdate: list[k]['LastUpdate'],
                    key: list[k]['key'],
                })
              }
              SetFlightNoList(selectItemList1)
              SetFlightInfoList(selectItemList2)
              GetDriver()
            }else{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
                }
            }
            SetLoading(false)
            },
            ()=>{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                }
                SetLoading(false);
            }
        )
    }
/*
{\"DriverID\":\"Chung Tak Kay\",
\"Name\":\"Chung Tak Kay\",
\"Password\":\"\",
\"DriverTelNo\":\"85291550206\",
\"DriverEmail\":\"\",
\"Status\":1,\"UUID\":\"\",
\"Remarks\":\"\",
\"ServiceProvider\":\"DCH\",
\"Vendor\":\"\",
\"CreatedBy\":\"MKImport\",
\"CreatedDatetime\":\"2023-09-14 18:17:16\",
\"UpdatedBy\":\"MKImport\",
\"UpdatedDatetime\":\"2023-09-14 18:17:16\",
\"key\":0}
*/
    const GetDriver = () =>{
        var para0 = "";
        var para1 = "action=GetDriver";
        var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
        var para3 = para1 + para2;
        //ConsoleLog(para3)
        ConsoleLog("GetDriver")
        GetData(para3,
            data => {
            ConsoleLog(data)
            if(data["result"] === "200"){
              var list = JSON.parse(data["itemObject"])
              var selectItemList:SelectOption[] = []
              var list2:any[] = []
              var selectItemList2:SelectOption[] = []
              for(var k = 0;k<list.length;k++){
                selectItemList.push({label: list[k]['Name'], value: list[k]['DriverID']})
                if(OrderDetailPageStatus.ServiceProvider === "DCH" && list[k]['ServiceProvider'] === "DCH"){
                    selectItemList2.push({label: list[k]['Name'], value: list[k]['DriverID']})
                    list2.push(list[k])
                }else if(OrderDetailPageStatus.ServiceProvider === "Consignment" && list[k]['ServiceProvider'] === "Consignment" && (list[k]['Vendor'] === OrderDetailPageStatus.VendorName || OrderDetailPageStatus.VendorName === "")){
                    selectItemList2.push({label: list[k]['Name'], value: list[k]['DriverID']})
                    list2.push(list[k])
                }
              }
              
              SetDriverDetailList(list)
              SetDriverFilterDetailList(list2)
              SetDriverFilterList(selectItemList2)
              GetVehicle()
            }else{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
                }
            }
            SetLoading(false)
            },
            ()=>{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                }
                SetLoading(false);
            }
        )
    }

    const GetVehicle = () =>{
        var para0 = "";
        var para1 = "action=GetVehicle";
        var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
        var para3 = para1 + para2;
        //ConsoleLog(para3)
        ConsoleLog("GetVehicle")
        GetData(para3,
            data => {
            //ConsoleLog(data)
            if(data["result"] === "200"){
                var list = JSON.parse(data["itemObject"])
                var selectItemList:SelectOption[] = []
                for(var k = 0;k<list.length;k++){
                selectItemList.push({label: list[k]['VehicleID'], value: list[k]['VehicleID']})
                }
                SetVehicleList(selectItemList)
                if(OrderDetailPageStatus.ServiceProvider === "DCH"){
                    SetVehicleFilterList(selectItemList)
                }else{
                    SetVehicleFilterList([])
                }
                GetTunnel()
                if(OrderDetailPageStatus.OrderNo !== ""){
                    //GetOrderDetail()
                }
            }else{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
                }
            }
            SetLoading(false)
            },
            ()=>{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                }
                SetLoading(false);
            }
        )
    }
/*
{
    "TunnelID": "Aberdeen Tunnel",
    "TunnelName": "Aberdeen Tunnel",
    "TunnelFee": 10,
    "SeqNo": 0,
    "Status": 1,
    "CreatedBy": "MKImport",
    "CreatedDatetime": "2023-09-14 18:17:16",
    "UpdatedBy": "MKImport",
    "UpdatedDatetime": "2023-09-14 18:17:16",
    "key": 0
}
*/
    const GetTunnel = () =>{
        var para0 = "";
        var para1 = "action=GetTunnel";
        var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
        var para3 = para1 + para2;
        //ConsoleLog(para3)
        ConsoleLog("GetTunnel")
        GetData(para3,
            data => {
            //ConsoleLog(data)
            if(data["result"] === "200"){
                if(data["itemObject"] !== "{}"){
                    var list = JSON.parse(data["itemObject"])
                    //ConsoleLog(list[0])
                    //var selectItemList:SelectOption[] = []
                    SetTunnelList(list)
                    // for(var k = 0;k<list.length;k++){
                    // selectItemList.push({label: list[k]['VehicleID'], value: list[k]['VehicleID']})
                    // }
                    // SetVehicleList(selectItemList)
                }
                GetVendor()
            }else{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
                }
            }
            SetLoading(false)
            },
            ()=>{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                }
                SetLoading(false);
            }
        )
    }
/*
"[{\"VendorName\":\"A-ONE\",\"key\":0}
,{\"VendorName\":\"Blacklane\",\"key\":1}
,{\"VendorName\":\"FRANDAK\",\"key\":2}
,{\"VendorName\":\"KEEN TRAVEL\",\"key\":3}
,{\"VendorName\":\"NEW MEGA\",\"key\":4}
,{\"VendorName\":\"Synergy\",\"key\":5}
,{\"VendorName\":\"TIM YIU\",\"key\":6}
,{\"VendorName\":\"VIGOR\",\"key\":7}
,{\"VendorName\":\"VITV (Avis)\",\"key\":8}
,{\"VendorName\":\"Winly Travel\",\"key\":9}
,{\"VendorName\":\"YUE TUNG\",\"key\":10}
,{\"VendorName\":\"三通\",\"key\":11}
,{\"VendorName\":\"寰宇\",\"key\":12}]",
*/
    const GetVendor = () =>{
        var para0 = "";
        var para1 = "action=GetVendor";
        var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
        var para3 = para1 + para2;
        //ConsoleLog(para3)
        //ConsoleLog("GetDriver")
        GetData(para3,
            data => {
            //ConsoleLog(data)
            if(data["result"] === "200"){
              var list = JSON.parse(data["itemObject"])
              var selectItemList:SelectOption[] = []
              for(var k = 0;k<list.length;k++){
                //ConsoleLog(list[k]['VendorName'])
                selectItemList.push({label: list[k]['VendorName'].toString(), value: list[k]['VendorName'].toString()})
              }
              SetServiceVenderList(selectItemList)
              SetServiceVenderListfiltered(selectItemList)
              //SetDriverList(selectItemList)
            }else{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
                }
            }
            SetLoading(false)
            },
            ()=>{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                }
                SetLoading(false);
            }
        )
    }

    const GetOrderDetail = () =>{
        var para0 = JSON.stringify({OrderNo:localStorage.getItem('DCHOrderNo')});
        var para1 = "action=GetOrderDetail";
        var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
        var para3 = para1 + para2 + "&itemObject=" + para0;
        //ConsoleLog(para3)
        ConsoleLog("GetOrderDetail")
        GetData(para3,
            data => {
            if(data["result"] === "200"){
                var orderListFromApi = JSON.parse(data["itemObject"])
                //ConsoleLog(data)

            }else{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
                }
            }
            SetLoading(false)
            },
            ()=>{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                }
                SetLoading(false);
            }
        )
        
        }

        const ImportImage = (Type,FileData,OrderNo,PhotoNo) =>{
            //data:image/png;base64,
            //data:image/bmp;base64,
            //data:image/jpg;base64,
            //data:image/jpeg;base64,
            var para0 = JSON.stringify(
                {
                  Type:Type,
                  FileData:FileData,
                  OrderNo:OrderNo,
                  PhotoNo:PhotoNo,
                }   
              )
            var para1 = "action=ImportImage";
            var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
            var para3 = para1 + para2 + "&itemObject=" + para0;
            //ConsoleLog(para3)
            //ConsoleLog("GetDriver")
            GetData(para3,
                data => {
                ConsoleLog(data)
                if(data["result"] === "200"){
                    reloadCount[`P${PhotoNo}`] = reloadCount[`P${PhotoNo}`] +1
                    SetReloadCount({...reloadCount})
                    GetImage(OrderNo,true)
                  //var list = JSON.parse(data["itemObject"])
                }else{
                    if(!IsErrorDialog){
                        SetIsErrorDialog(true)
                        oneButtonConfirmDialog("Error","Please Try Again",()=>{SetIsErrorDialog(false);window.location.reload()})
                    }
                }
                SetLoading(false)
                },
                ()=>{
                    if(!IsErrorDialog){
                        SetIsErrorDialog(true)
                        oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false);window.location.reload()})
                    }
                    SetLoading(false);
                }
            )
        }

        const DeleteImage = (OrderNo,PhotoNo) =>{
            var para0 = JSON.stringify(
                {
                  Type:"Photo",
                  OrderNo:OrderNo,
                  PhotoNo:PhotoNo,
                }   
              )
            var para1 = "action=DeleteImage";
            var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
            var para3 = para1 + para2 + "&itemObject=" + para0;
            //ConsoleLog(para3)
            //ConsoleLog("GetDriver")
            GetData(para3,
                data => {
                ConsoleLog(data)
                if(data["result"] === "200"){
                    GetImage(OrderNo,false)
                }else{
                    if(!IsErrorDialog){
                        SetIsErrorDialog(true)
                        oneButtonConfirmDialog("Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                    }
                    SetLoading(false);
                }
                SetLoading(false)
                },
                ()=>{
                    if(!IsErrorDialog){
                        SetIsErrorDialog(true)
                        oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                    }
                    SetLoading(false);
                }
            )
        }
/*
[{"Photo1":"","Photo2":"","Photo3":"","Photo4":"","Photo5":"","Photo6":"","Photo7":"","Photo8":"","Photo9":"","Photo10":"","key":0}]
*/
        const GetImage = (OrderNo,reload) =>{
            var para0 = JSON.stringify(
                {
                  OrderNo:OrderNo,
                }   
              )
            var para1 = "action=GetImage";
            var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
            var para3 = para1 + para2 + "&itemObject=" + para0;
            //ConsoleLog(para3)
            //ConsoleLog("GetDriver")
            GetData(para3,
                data => {
                ConsoleLog(data)
                if(data["result"] === "200"){
                  var list = JSON.parse(data["itemObject"])
                  SetPhotoList(list)
                  //ConsoleLog(list[0]["Photo1"])
                }else{
                    if(!IsErrorDialog){
                        SetIsErrorDialog(true)
                        oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
                    }
                }
                SetLoading(false)
                if(reload){
                    //emptyCache()
                    //window.location.reload()
                    //navigate("/orderdetail")
                }
                },
                ()=>{
                    if(!IsErrorDialog){
                        SetIsErrorDialog(true)
                        oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                    }
                    SetLoading(false);
                }
            )
        }

    const saveOrderDetailPageStatus = () =>{
        const tunnelStringTemp = JSON.stringify(TunnelTimesList).replace("{","").replace("}","").replace(/"/g,'');
        const tunnelStringTemp2 = JSON.stringify(TunnelPriceList).replace("{","").replace("}","").replace(/"/g,'');
        //ConsoleLog(tunnelStringTemp)
        
        // const tunnelStringTemp = `香港仔:${TunnelTimes01},`+
        // `獅子山 (易通行):${TunnelTimes02},`+
        // `城隧(易通行):${TunnelTimes03},` +
        // `尖山 (易通行):${TunnelTimes04},` +
        // `紅隧 (易通行):${TunnelTimes05},` +
        // `東隧:${TunnelTimes06},` +
        // `西隧:${TunnelTimes07},` +
        // `大老山:${TunnelTimes08},` +
        // `大欖:${TunnelTimes09},` +
        // `愉景灣:${TunnelTimes10},` +
        // `青馬大橋:${TunnelTimes11},`+
        // `將軍澳:${TunnelTimes12},`

        SetOrderDetailPageStatus({
            ...{ OrderNo:OrderDetailPageStatus.OrderNo,
                EformOrderNo:OrderDetailPageStatus.EformOrderNo,
                CarType:OrderDetailPageStatus.CarType,
                Journey:OrderDetailPageStatus.Journey,
                CustomerType:OrderDetailPageStatus.CustomerType,
                CustomerName:OrderDetailPageStatus.CustomerName,
                Address:OrderDetailPageStatus.Address,
                ContactName1:OrderDetailPageStatus.ContactName1,
                ContactTelNo1:OrderDetailPageStatus.ContactTelNo1,
                ContactTelNo2:OrderDetailPageStatus.ContactTelNo2,
                Title:OrderDetailPageStatus.Title,
                PassengerName:OrderDetailPageStatus.PassengerName,
                PassengerTelNo:OrderDetailPageStatus.PassengerTelNo,
                EstimatedOrderDuration:OrderDetailPageStatus.EstimatedOrderDuration,
                Pickup:OrderDetailPageStatus.Pickup,
                Destination:OrderDetailPageStatus.Destination,
                StopOver1:OrderDetailPageStatus.StopOver1,
                StopOver2:OrderDetailPageStatus.StopOver2,
                StopOver3:OrderDetailPageStatus.StopOver3,
                FlightNo:OrderDetailPageStatus.FlightNo,
                FlightSchedule:OrderDetailPageStatus.FlightSchedule,
                FlightStatus:OrderDetailPageStatus.FlightStatus,
                NoOfPassenger:OrderDetailPageStatus.NoOfPassenger,
                NoOfLuggage:OrderDetailPageStatus.NoOfLuggage,
                FirstReminder:OrderDetailPageStatus.FirstReminder,
                SpecialInstructions:OrderDetailPageStatus.SpecialInstructions,
                HireRequest:OrderDetailPageStatus.HireRequest,
                OrderPickUpDatetime:ActualOrderPickUpDate+" "+ActualOrderPickUpTime,
                OrderCharge:OrderDetailPageStatus.OrderCharge,
                AdditionalCharge:OrderDetailPageStatus.AdditionalCharge,
                ParkingCharge:OrderDetailPageStatus.ParkingCharge,
                TunnelCharge:OrderDetailPageStatus.TunnelCharge,
                TunnelString:tunnelStringTemp,
                TunnelChargeList:tunnelStringTemp2,
                AssignVehicleID:OrderDetailPageStatus.AssignVehicleID,
                AssignDriverID:OrderDetailPageStatus.AssignDriverID,
                DriverName:OrderDetailPageStatus.DriverName,
                DriverMobile:OrderDetailPageStatus.DriverMobile,
                ServiceProvider:OrderDetailPageStatus.ServiceProvider,
                VendorName:OrderDetailPageStatus.VendorName,
                VendorDriver:OrderDetailPageStatus.VendorDriver,
                Status:OrderDetailPageStatus.Status,
                HavePhoto:OrderDetailPageStatus.HavePhoto,
                HaveMeetNGreet:OrderDetailPageStatus.HaveMeetNGreet,
                Remarks:OrderDetailPageStatus.Remarks,
                ReadyToUpload:OrderDetailPageStatus.ReadyToUpload,
                CreatedBy:OrderDetailPageStatus.CreatedBy,
                CreatedDatetime:OrderDetailPageStatus.CreatedDatetime,
                UpdatedBy:OrderDetailPageStatus.UpdatedBy,
                UpdatedDatetime:OrderDetailPageStatus.UpdatedDatetime,
                DriverStartWaitDatetime:OrderDetailPageStatus.DriverStartWaitDatetime,
                DriverWaitDuration:`${(DriverWaitDurationM*60) + DriverWaitDurationS}`,
                DriverPickUpDatetime:OrderDetailPageStatus.DriverPickUpDatetime,
                DriverDropOffDatetime:OrderDetailPageStatus.DriverDropOffDatetime,
                OrderLock:OrderDetailPageStatus.OrderLock,
                SMSEnable:OrderDetailPageStatus.SMSEnable,
            } }
        );
    }
    const UpdateOrder = (isCreate,isClose) =>{
        var paraMap1 = { OrderNo:OrderDetailPageStatus.OrderNo,
            CarType:OrderDetailPageStatus.CarType,
            Journey:OrderDetailPageStatus.Journey,
            CustomerType:OrderDetailPageStatus.CustomerType,
            CustomerName:OrderDetailPageStatus.CustomerName,
            Address:OrderDetailPageStatus.Address,
            ContactName1:OrderDetailPageStatus.ContactName1,
            ContactTelNo1:OrderDetailPageStatus.ContactTelNo1,
            ContactTelNo2:OrderDetailPageStatus.ContactTelNo2,
            Title:OrderDetailPageStatus.Title,
            PassengerName:OrderDetailPageStatus.PassengerName,
            PassengerTelNo:OrderDetailPageStatus.PassengerTelNo,
            EstimatedOrderDuration:OrderDetailPageStatus.EstimatedOrderDuration,
            Pickup:OrderDetailPageStatus.Pickup,
            Destination:OrderDetailPageStatus.Destination,
            StopOver1:OrderDetailPageStatus.StopOver1,
            StopOver2:OrderDetailPageStatus.StopOver2,
            StopOver3:OrderDetailPageStatus.StopOver3,
            FlightNo:OrderDetailPageStatus.FlightNo,
            FlightSchedule:OrderDetailPageStatus.FlightSchedule,
            FlightStatus:OrderDetailPageStatus.FlightStatus,
            NoOfPassenger:OrderDetailPageStatus.NoOfPassenger,
            NoOfLuggage:OrderDetailPageStatus.NoOfLuggage,
            FirstReminder:OrderDetailPageStatus.FirstReminder,
            SpecialInstructions:OrderDetailPageStatus.SpecialInstructions,
            HireRequest:OrderDetailPageStatus.HireRequest,
            OrderPickUpDatetime:ActualOrderPickUpDate+" "+ActualOrderPickUpTime,
            OrderCharge:OrderDetailPageStatus.OrderCharge,
            AdditionalCharge:OrderDetailPageStatus.AdditionalCharge,
            ParkingCharge:OrderDetailPageStatus.ParkingCharge,
            TunnelCharge:OrderDetailPageStatus.TunnelCharge,
            TunnelString:OrderDetailPageStatus.TunnelString,
            TunnelChargeList:OrderDetailPageStatus.TunnelChargeList,
            AssignVehicleID:OrderDetailPageStatus.AssignVehicleID,
            AssignDriverID:OrderDetailPageStatus.AssignDriverID,
            DriverName:OrderDetailPageStatus.DriverName,
            DriverMobile:OrderDetailPageStatus.DriverMobile,
            ServiceProvider:OrderDetailPageStatus.ServiceProvider,
            VendorName:OrderDetailPageStatus.VendorName,
            VendorDriver:OrderDetailPageStatus.VendorDriver,
            Status:OrderDetailPageStatus.Status,
            HavePhoto:OrderDetailPageStatus.HavePhoto,
            HaveMeetNGreet:OrderDetailPageStatus.HaveMeetNGreet,
            Remarks:OrderDetailPageStatus.Remarks,
            ReadyToUpload:OrderDetailPageStatus.ReadyToUpload,
            CreatedBy:OrderDetailPageStatus.CreatedBy,
            CreatedDatetime:OrderDetailPageStatus.CreatedDatetime,
            UpdatedBy:OrderDetailPageStatus.UpdatedBy,
            UpdatedDatetime:OrderDetailPageStatus.UpdatedDatetime,
            OrderLock:OrderDetailPageStatus.OrderLock,
            SMSEnable:(OrderDetailPageStatus.AssignDriverID === "" || OrderDetailPageStatus.AssignVehicleID === "")?"0":OrderDetailPageStatus.SMSEnable,
        }
        //added driver duration at this para
        var paraMap2 = { OrderNo:OrderDetailPageStatus.OrderNo,
            CarType:OrderDetailPageStatus.CarType,
            Journey:OrderDetailPageStatus.Journey,
            CustomerType:OrderDetailPageStatus.CustomerType,
            CustomerName:OrderDetailPageStatus.CustomerName,
            Address:OrderDetailPageStatus.Address,
            ContactName1:OrderDetailPageStatus.ContactName1,
            ContactTelNo1:OrderDetailPageStatus.ContactTelNo1,
            ContactTelNo2:OrderDetailPageStatus.ContactTelNo2,
            Title:OrderDetailPageStatus.Title,
            PassengerName:OrderDetailPageStatus.PassengerName,
            PassengerTelNo:OrderDetailPageStatus.PassengerTelNo,
            EstimatedOrderDuration:OrderDetailPageStatus.EstimatedOrderDuration,
            Pickup:OrderDetailPageStatus.Pickup,
            Destination:OrderDetailPageStatus.Destination,
            StopOver1:OrderDetailPageStatus.StopOver1,
            StopOver2:OrderDetailPageStatus.StopOver2,
            StopOver3:OrderDetailPageStatus.StopOver3,
            FlightNo:OrderDetailPageStatus.FlightNo,
            FlightSchedule:OrderDetailPageStatus.FlightSchedule,
            FlightStatus:OrderDetailPageStatus.FlightStatus,
            NoOfPassenger:OrderDetailPageStatus.NoOfPassenger,
            NoOfLuggage:OrderDetailPageStatus.NoOfLuggage,
            FirstReminder:OrderDetailPageStatus.FirstReminder,
            SpecialInstructions:OrderDetailPageStatus.SpecialInstructions,
            HireRequest:OrderDetailPageStatus.HireRequest,
            OrderPickUpDatetime:ActualOrderPickUpDate+" "+ActualOrderPickUpTime,
            OrderCharge:OrderDetailPageStatus.OrderCharge,
            AdditionalCharge:OrderDetailPageStatus.AdditionalCharge,
            ParkingCharge:OrderDetailPageStatus.ParkingCharge,
            TunnelCharge:OrderDetailPageStatus.TunnelCharge,
            TunnelString:OrderDetailPageStatus.TunnelString,
            TunnelChargeList:OrderDetailPageStatus.TunnelChargeList,
            AssignVehicleID:OrderDetailPageStatus.AssignVehicleID,
            AssignDriverID:OrderDetailPageStatus.AssignDriverID,
            DriverName:OrderDetailPageStatus.DriverName,
            DriverMobile:OrderDetailPageStatus.DriverMobile,
            ServiceProvider:OrderDetailPageStatus.ServiceProvider,
            VendorName:OrderDetailPageStatus.VendorName,
            VendorDriver:OrderDetailPageStatus.VendorDriver,
            Status:OrderDetailPageStatus.Status,
            HavePhoto:OrderDetailPageStatus.HavePhoto,
            HaveMeetNGreet:OrderDetailPageStatus.HaveMeetNGreet,
            Remarks:OrderDetailPageStatus.Remarks,
            ReadyToUpload:OrderDetailPageStatus.ReadyToUpload,
            CreatedBy:OrderDetailPageStatus.CreatedBy,
            CreatedDatetime:OrderDetailPageStatus.CreatedDatetime,
            UpdatedBy:OrderDetailPageStatus.UpdatedBy,
            UpdatedDatetime:OrderDetailPageStatus.UpdatedDatetime,
            DriverStartWaitDatetime:OrderDetailPageStatus.DriverStartWaitDatetime,
            DriverWaitDuration:`${(DriverWaitDurationM*60) + DriverWaitDurationS}`,
            DriverPickUpDatetime:OrderDetailPageStatus.DriverPickUpDatetime,
            DriverDropOffDatetime:OrderDetailPageStatus.DriverDropOffDatetime,
            OrderLock:OrderDetailPageStatus.OrderLock,
            SMSEnable:(OrderDetailPageStatus.AssignDriverID === "" || OrderDetailPageStatus.AssignVehicleID === "")?"0":OrderDetailPageStatus.SMSEnable,}
        //ConsoleLog(PassengerNameRerf.current.value)
        //return
        //ConsoleLog(ActualOrderPickUpDate+" "+ActualOrderPickUpTime)
        //return
        if(ActualOrderPickUpDate === "" || ActualOrderPickUpDate === " " ||　ActualOrderPickUpDate === null){
            oneButtonConfirmDialog("Please input PickupDate","",()=>{})
            return
        }
        if(ActualOrderPickUpTime === "" || ActualOrderPickUpTime === " " ||ActualOrderPickUpTime === null){
            oneButtonConfirmDialog("Please input PickupTime","",()=>{})
            return
        }

        SetLoading(true);

        var para0 = JSON.stringify(((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))?paraMap2:paraMap1);
        var para1 = "action=UpdateOrder";
        if(OrderDetailPageStatus.OrderNo === "" || isCreate){
            para1 = "action=CreateOrder";
        }
        var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
        var para3 = para1 + para2 + "&itemObject=" + para0.replace(/&#/g,"%26%20%23").replace(/&/g,"%26").replace(/#/g,"%23");
        ConsoleLog(para3)
        //ConsoleLog("UpdateOrder")
        GetData(para3,
          data => {ConsoleLog(data)
            ConsoleLog(data["result"])
            if(data["result"] === "200"){
              //var orderListFromApi = JSON.parse(data["itemObject"])
              //ConsoleLog(data)
              //setOrderList(orderListFromApi)
              //SetLoading(false)
              //orderListFromApi = data["itemObject"]
              //ConsoleLog(data["itemObject"][0])
              if(OrderDetailPageStatus.OrderNo === "" || isCreate){
                oneButtonConfirmDialog("Create Order Success","",()=>{navigate("/orderdetailp")})
              }else{
                oneButtonConfirmDialog("Save Edit Order Success","",()=>{navigate("/orderdetailp")})
              }
              if(isClose){
                window.close()
              }
            }else if(data["result"] === "201"){
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
                }
            }else{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                }
            }
            SetLoading(false)
          },
            ()=>{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                }
                SetLoading(false);
            }
        )
      }

      const UpdateOrderLock = (isLock) =>{
        
        var para0 = JSON.stringify({ OrderNo:OrderDetailPageStatus.OrderNo,OrderLock:isLock});
        var para1 = "action=UpdateOrder";
        var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
        var para3 = para1 + para2 + "&itemObject=" + para0.replace(/&#/g,"%26%20%23").replace(/&/g,"%26").replace(/#/g,"%23");
        ConsoleLog(para3)
        //ConsoleLog("UpdateOrder")
        GetData(para3,
          data => {
            SetLoading(false)
          },
            ()=>{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                }
                SetLoading(false);
            }
        )
      }

      const ResendOrderSMS = (orderNo,type) =>{
        var para0 = JSON.stringify({OrderNo:orderNo,Type:type});
        var para1 = "action=ResendOrderSMS";
        var para2 = `&token=${localStorage.getItem('DCHtoken')}`;
        var para3 = para1 + para2 + "&itemObject=" + para0;
        //ConsoleLog(para3)
        GetData(para3,
          data => {
            if(data["result"] === "200"){
              
            }else{
              if(!IsErrorDialog){
                SetIsErrorDialog(true)
                oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
              }
            }
            SetLoading(false)
          },
          ()=>{
            if(!IsErrorDialog){
              SetIsErrorDialog(true)
              oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false);window.location.replace(webUrl)})
            }
            SetLoading(false);
          }
        )
      }
      
/*
    SetOrderNo(orderListFromApi[0].OrderNo)
    SetBookingDatetime(orderListFromApi[0].BookingDatetime)
    SetCarType(orderListFromApi[0].CarType)
    SetJourney(orderListFromApi[0].Journey)
    SetTitle(orderListFromApi[0].Title)
    SetCustomerType(orderListFromApi[0].CustomerType)
    SetCustomerName(orderListFromApi[0].CustomerName)
    SetAddress(orderListFromApi[0].Address)
    SetContactName1(orderListFromApi[0].ContactName1)
    SetContactTelNo1(orderListFromApi[0].ContactTelNo1)
    SetPassengerName(orderListFromApi[0].PassengerName)
    SetPassengerTelNo(orderListFromApi[0].PassengerTelNo)
    SetEstimatedOrderDuration(orderListFromApi[0].EstimatedOrderDuration)

    SetActualPickup(orderListFromApi[0].ActualPickup)
    SetActualDestination(orderListFromApi[0].ActualDestination)
    SetActualStopOver1(orderListFromApi[0].ActualStopOver1)
    SetActualStopOver2(orderListFromApi[0].ActualStopOver2)
    SetActualStopOver3(orderListFromApi[0].ActualStopOver3)

    SetPickup(orderListFromApi[0].Pickup)
    SetDestination(orderListFromApi[0].Destination)
    SetStopOver1(orderListFromApi[0].StopOver1)
    SetStopOver2(orderListFromApi[0].StopOver2)
    SetStopOver3(orderListFromApi[0].StopOver3)

    SetActualFlightNo(orderListFromApi[0].ActualFlightNo)
    SetFlightNo(orderListFromApi[0].FlightNo)

    SetFlightStatus(orderListFromApi[0].FlightStatus)
    SetFlightSchedule((orderListFromApi[0].ActualFlightSchedule.length >= 16)?orderListFromApi[0].ActualFlightSchedule.substring(11,16):orderListFromApi[0].ActualFlightSchedule)
    SetNoOfPassenger(orderListFromApi[0].NoOfPassenger)
    SetNoOfLuggage(orderListFromApi[0].NoOfLuggage)
    SetHireRequest(orderListFromApi[0].HireRequest)
    SetOrderCharge(orderListFromApi[0].OrderCharge)
    SetAdditionalCharge(orderListFromApi[0].AdditionalCharge)
    SetParkingCharge(orderListFromApi[0].ParkingCharge)
    SetTunnelCharge(orderListFromApi[0].TunnelCharge)

    SetAssignVehicleID(orderListFromApi[0].AssignVehicleID)
    SetAssignDriverID(orderListFromApi[0].AssignDriverID)
    SetDriverName(orderListFromApi[0].DriverName)
    SetDriverMobile(orderListFromApi[0].DriverMobile)

    SetStatus(orderListFromApi[0].Status)
    SetRemarks(orderListFromApi[0].Remarks)
    SetVendorName(orderListFromApi[0].VendorName)
    SetVendorDriver(orderListFromApi[0].VendorDriver)
    SetHavePhoto(orderListFromApi[0].HavePhoto)
    SetHaveMeetNGreet(orderListFromApi[0].HaveMeetNGreet)
    SetServiceProvider(orderListFromApi[0].ServiceProvider)
    SetFirstReminder(orderListFromApi[0].FirstReminder)
    SetSpecialInstructions(orderListFromApi[0].SpecialInstructions)
    SetActualOrderPickUpDatetime(orderListFromApi[0].ActualOrderPickUpDatetime)

    var aopud = orderListFromApi[0].ActualOrderPickUpDatetime.length >=10 ? orderListFromApi[0].ActualOrderPickUpDatetime.substring(0,10):TolocalDateString(new Date().toISOString()).substring(0,10)
    var aoput = orderListFromApi[0].ActualOrderPickUpDatetime.length >=16 ? orderListFromApi[0].ActualOrderPickUpDatetime.substring(11,16):TolocalDateString(new Date().toISOString()).substring(10,16)
    SetActualOrderPickUpDate(aopud)
    SetActualOrderPickUpTime(aoput)

    var opud = orderListFromApi[0].OrderPickUpDatetime.length >=10 ? orderListFromApi[0].OrderPickUpDatetime.substring(0,10):TolocalDateString(new Date().toISOString()).substring(0,10)
    var oput = orderListFromApi[0].OrderPickUpDatetime.length >=16 ? orderListFromApi[0].OrderPickUpDatetime.substring(11,16):TolocalDateString(new Date().toISOString()).substring(10,16)
    SetOrderPickUpDate(opud)
    SetOrderPickUpTime(oput)

    SetDriverDropOffDatetime(orderListFromApi[0].DriverDropOffDatetime)
    SetDriverDropOffDatetime2(orderListFromApi[0].DriverDropOffDatetime2)
    SetDriverPickUpDatetime(orderListFromApi[0].DriverPickUpDatetime)
    SetDriverPickUpDatetime2(orderListFromApi[0].DriverPickUpDatetime2)
    SetDriverStartWaitDatetime(orderListFromApi[0].DriverStartWaitDatetime)
    SetDriverStartWaitDatetime2(orderListFromApi[0].DriverStartWaitDatetime2)
    SetDriverWaitDuration(orderListFromApi[0].DriverWaitDuration)
    SetDriverWaitDuration2(orderListFromApi[0].DriverWaitDuration2)
    SetTunnelChargeList(orderListFromApi[0].TunnelChargeList)
    SetTunnelString(orderListFromApi[0].TunnelString)

    SetIsEstHrDisable((orderListFromApi[0].Journey === "Hourly Rental")?false:true)

    var tunnelTimesMapString = `{"${orderListFromApi[0].TunnelString.replace(/:/g,'":"').replace(/,/g,'","')}}`.replace(',"}','}').replace('{"}','{}')

    var t01 = (tunnelTimesMap["香港仔"] === undefined)? 0: tunnelTimesMap["香港仔"]
    var t02 = (tunnelTimesMap["獅子山 (易通行)"] === undefined)? 0: tunnelTimesMap["獅子山 (易通行)"]
    var t03 = (tunnelTimesMap["城隧(易通行)"] === undefined)? 0: tunnelTimesMap["城隧(易通行)"]
    var t04 = (tunnelTimesMap["尖山 (易通行)"] === undefined)? 0: tunnelTimesMap["尖山 (易通行)"]
    var t05 = (tunnelTimesMap["紅隧 (易通行)"] === undefined)? 0: tunnelTimesMap["紅隧 (易通行)"]
    var t06 = (tunnelTimesMap["東隧"] === undefined)? 0: tunnelTimesMap["東隧"]
    var t07 = (tunnelTimesMap["西隧"] === undefined)? 0: tunnelTimesMap["西隧"]
    var t08 = (tunnelTimesMap["大老山"] === undefined)? 0: tunnelTimesMap["大老山"]
    var t09 = (tunnelTimesMap["大欖"] === undefined)? 0: tunnelTimesMap["大欖"]
    var t10 = (tunnelTimesMap["愉景灣"] === undefined)? 0: tunnelTimesMap["愉景灣"]
    var t11 = (tunnelTimesMap["青馬大橋"] === undefined)? 0: tunnelTimesMap["青馬大橋"]
    var t12 = (tunnelTimesMap["將軍澳"] === undefined)? 0: tunnelTimesMap["將軍澳"]

    SetTunnelTimes01(t01)
    SetTunnelTimes02(t02)
    SetTunnelTimes03(t03)
    SetTunnelTimes04(t04)
    SetTunnelTimes05(t05)
    SetTunnelTimes06(t06)
    SetTunnelTimes07(t07)
    SetTunnelTimes08(t08)
    SetTunnelTimes09(t09)
    SetTunnelTimes10(t10)
    SetTunnelTimes11(t11)
    SetTunnelTimes12(t12)*/
    const [AuditTrailContent,setAuditTrailContent] = useState([])
    const [AuditTrailTitle,setAuditTrailTitle] = useState("AuditTrail")
    const [IsAuditTrail,setIsAuditTrail] = useState(false)

    const GetAuditTrailByOrderNo = (OrderNo) =>{
        //SetLoading(true)
        //ConsoleLog("API:"+OrderNo)
        var para0 = JSON.stringify({'OrderNo':OrderNo,"System":""});
        var para1 = "action=GetAuditTrailByOrderNo";
        var para2 = `&token=${localStorage.getItem("DCHtoken")}`;
        var para3 = para1 + para2 + "&itemObject=" + para0;
        ConsoleLog(para3)
        GetData(para3,
            data => {
            ConsoleLog(data)
            if(data["result"] === "200"){
                if(data["itemObject"] !== "{}"){
                    var list = JSON.parse(data["itemObject"].replace("	","\t"))
                    var listsize = list.length
                    var AuditShowing:any = []
                    AuditShowing.push(<div style={{background:"#DDDDDD",width:"100%",height:1}} />)
                    if(listsize > 0){
    
                        for(var i = 0;i<listsize;i++){
                            ConsoleLog(list[i])
                            //AuditShowing.push(<Row><Text>{"AuditDatetime : "+list[i]["AuditDatetime"]}</Text></Row>)
                            var AuditDatetime = list[i]["AuditDatetime"]
                            var System = list[i]["System"]
                            //var Category = list[i]["Category"] .replace(/\n/g,"\\n").replace(/\\/g,"").replace(/\'/g,"").replace(/\"/g,"").replace(/\{/g,"").replace(/\}/g,"").replace(/\[/g,"").replace(/\]/g,"")
                            var UpdatedBy = list[i]["UpdatedBy"]
                            var list2 = JSON.parse(list[i]["Data"].replace(/\n/g,"\\n"))
                            var list2size = list2.length
                            var editData:any = []
                            for(var j = 0;j<list2size;j++){
                                ConsoleLog(list2[j])
                                ConsoleLog(list2[0]["Type"])
                                ConsoleLog(list2[0]["Old"])
                                ConsoleLog(list2[0]["New"])
                                editData.push(<Row>
                                        <Col span={8}><Text>{list2[j]["Type"]}</Text></Col>
                                        <Col span={8}><Text>{list2[j]["Old"]}</Text></Col>
                                        <Col span={8}><Text>{list2[j]["New"]}</Text></Col>
                                    </Row>)
                            }
                            AuditShowing.push(<Row style={{width:900}}>
                                <Col span={4}><Text>{AuditDatetime}</Text></Col>
                                <Col span={4}><Text>{System}</Text></Col>
                                <Col span={4}><Text>{UpdatedBy}</Text></Col>
                                <Col span={12}>{editData}</Col>
                                </Row>)
                            AuditShowing.push(<div style={{background:"#DDDDDD",width:900,height:1}} />)
                        }
                        setAuditTrailContent(AuditShowing)
                        setIsAuditTrail(true)
                    }else{
                        AuditShowing.push(<Row><Text>{"No Record"}</Text></Row>)
                        setAuditTrailContent(AuditShowing)
                        setIsAuditTrail(true)
                    }
                }
            }else{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Error","Please Login Again",()=>{SetIsErrorDialog(false)})
                }
            }
            SetLoading(false)
            },
            ()=>{
                if(!IsErrorDialog){
                    SetIsErrorDialog(true)
                    oneButtonConfirmDialog("Network Error","Please Try Again",()=>{SetIsErrorDialog(false)})
                }
                SetLoading(false);
            }
        )
    }
    

    var IDp = "";
    
    //ConsoleLog(TolocalDateString(new Date().toISOString()).substring(0,10))
    //ConsoleLog(TolocalDateString(new Date().toISOString()).substring(11,16))
    //data

    useEffect(
        ()=>{
            const timer = setTimeout(()=>{
                //ConsoleLog("1")
                GetOrderStatusReference()
                initailTunnelString()
                //GetFlightSchedule()
                // GetDriver()
                // GetVehicle()
                // if(localStorage.getItem('DCHOrderNo') !== ""){
                //     GetOrderDetail()
                // }

            },100)
            return () => clearTimeout(timer)
        }
      ,[]);

      useEffect(
        () => {
          const interval = setInterval(() => {
            //ConsoleLog("1s")
            saveOrderDetailPageStatus()
          }, 500);
          return () => clearInterval(interval);
        }
      )
      const initailTunnelString = ()=>{
        var tunnelTimesMap = JSON.parse(localStorage.getItem('DCHtunnelTimesMapString')!)
        SetTunnelTimesList(tunnelTimesMap)
        var tunnelPriceMap = JSON.parse(localStorage.getItem('DCHtunnelPriceMapString')!)
        SetTunnelPriceList(tunnelPriceMap)
    }
    //<p style={{height:500,overflow:"scroll"}}>
    //<div style={{height:580,overflow:"hidden"}}>
    //<Col span={4}><Input  value={OrderNo} onChange={(e)=>{SetOrderNo(e.target.value)}} style={{ width: "98%" }}/></Col>
    //{(localStorage.getItem('DCHOrderNo') === "")?dialog1:UiForm}
    const tunnelUI = ()=>{
        var tunnelTimesMap = JSON.parse(localStorage.getItem('DCHtunnelTimesMapString')!)
        var tunnelPriceMap = JSON.parse(localStorage.getItem('DCHtunnelPriceMapString')!)
        //ConsoleLog(localStorage.getItem('DCHtunnelPriceMapString'))
        if(TunnelList !== null && TunnelList.length > 0){
            //ConsoleLog(TunnelList[0])
            //ConsoleLog(TunnelList[0]['TunnelID'])
            //ConsoleLog(tunnelTimesMap[TunnelList[0]['TunnelID']])
            //ConsoleLog(`${tunnelTimesMap[`${TunnelList[0]['TunnelID']}`]}`)
            //ConsoleLog(`${tunnelTimesMap[`${TunnelList[0]['TunnelID']}`]}` === "undefined"?"0":`${tunnelTimesMap[`${TunnelList[0]['TunnelID']}`]}`)
            //ConsoleLog(tunnelTimesMap[0])
        }
        //ConsoleLog(TunnelList[0]['TunnelID'])
        // ConsoleLog(`${tunnelTimesMap[`${TunnelList[0]['TunnelID']}`]}`)
        // ConsoleLog(tunnelTimesMap[0])

        //ConsoleLog(localStorage.getItem('DCHtunnelTimesMapString')!)
        //SetTunnelTimesList(tunnelTimesMap)
        //TunnelTimesList[TunnelList[k]['TunnelID']] = e;SetTunnelTimesList({...TunnelTimesList})
        //!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))
        var tunnelUIList:any[] = []
        //var tunnelTimesMapTemp:any = {}
        if(TunnelList !== null && TunnelList.length > 0){
            for(var k = 0;k<TunnelList.length;k++){
                //ConsoleLog(TunnelList[k]['TunnelID'])
                //ConsoleLog(`${tunnelTimesMap[`${TunnelList[k]['TunnelID']}`]}` === "undefined"?"0":`${tunnelTimesMap[`${TunnelList[k]['TunnelID']}`]}`)
                const index = k;
                //tunnelTimesMapTemp[TunnelList[index]['TunnelID']] = tunnelTimesMap[`${TunnelList[index]['TunnelID']}`]
                tunnelUIList.push(
                <Row>
                    <Col span={4}>
                        <InputNumber disabled={true && (OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || !((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel") || Status?.includes("Uploaded")) )} size="small" 
                        style={{width:"99%"}} min={"0"} max={"10"}  controls={false}
                        //value={"5"}
                        defaultValue={tunnelTimesMap[`${TunnelList[index]['TunnelID']}`] === undefined?"0":tunnelTimesMap[`${TunnelList[index]['TunnelID']}`]} 
                        onChange={(e)=>{TunnelTimesList[TunnelList[index]['TunnelID']] = e;SetTunnelTimesList({...TunnelTimesList})}} /></Col>
                    <Col span={5}>
                        <InputNumber disabled={true && (OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || !((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel") || Status?.includes("Uploaded")) )} size="small" 
                        style={{width:"99%"}} min={"0"} max={"10000"}   controls={false}
                        //value={"5"}
                        defaultValue={tunnelPriceMap[`${TunnelList[index]['TunnelID']}`] === undefined?"0":tunnelPriceMap[`${TunnelList[index]['TunnelID']}`]} 
                        onChange={(e)=>{TunnelPriceList[TunnelList[index]['TunnelID']] = e;SetTunnelPriceList({...TunnelPriceList})}} /></Col>
                    <Col span={15}>
                            <Text onChange={(e)=>{/*香港仔*/}}>{`　${TunnelList[index]['TunnelName']}`.replace("Tunnel","").replace("Fee","").replace("Seater","S").replace("seater","S")}</Text>
                    </Col>
                </Row>)
            }
            //SetTunnelTimesList(tunnelTimesMapTemp)
        }

        return(
            <div>
                {tunnelUIList}
            </div>
        )
        // return(
        //     <div>
        //         <Row><Col span={8}><InputNumber disabled={!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} size="small" style={{width:"60%"}} min={"0"} max={"10"} defaultValue={TunnelTimes01!} onChange={(e)=>{SetTunnelTimes01(`${e}`)}} /></Col><Col span={16}><Text onChange={(e)=>{/*香港仔*/}}>Aberdeen Tunnel</Text></Col></Row>
        //         <Row><Col span={8}><InputNumber disabled={!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} size="small" style={{width:"60%"}} min={"0"} max={"10"} defaultValue={TunnelTimes02!} onChange={(e)=>{SetTunnelTimes02(`${e}`)}} /></Col><Col span={16}><Text onChange={(e)=>{/*獅子山 (易通行)*/}}>Lion Rock Tunnel</Text></Col></Row>
        //         <Row><Col span={8}><InputNumber disabled={!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} size="small" style={{width:"60%"}} min={"0"} max={"10"} defaultValue={TunnelTimes03!} onChange={(e)=>{SetTunnelTimes03(`${e}`)}} /></Col><Col span={16}><Text onChange={(e)=>{/*城隧(易通行)*/}}>Shing Mun Tunnels</Text></Col></Row>
        //         <Row><Col span={8}><InputNumber disabled={!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} size="small" style={{width:"60%"}} min={"0"} max={"10"} defaultValue={TunnelTimes04!} onChange={(e)=>{SetTunnelTimes04(`${e}`)}} /></Col><Col span={16}><Text onChange={(e)=>{/*尖山 (易通行)*/}}>Eagle's Nest Tunnel</Text></Col></Row>
        //         <Row><Col span={8}><InputNumber disabled={!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} size="small" style={{width:"60%"}} min={"0"} max={"10"} defaultValue={TunnelTimes05!} onChange={(e)=>{SetTunnelTimes05(`${e}`)}} /></Col><Col span={16}><Text onChange={(e)=>{/*紅隧 (易通行)*/}}>Cross Harbour Tunnel</Text></Col></Row>
        //         <Row><Col span={8}><InputNumber disabled={!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} size="small" style={{width:"60%"}} min={"0"} max={"10"} defaultValue={TunnelTimes06!} onChange={(e)=>{SetTunnelTimes06(`${e}`)}} /></Col><Col span={16}><Text onChange={(e)=>{/*東隧*/}}>Eastern Harbour Crossing</Text></Col></Row>
        //         <Row><Col span={8}><InputNumber disabled={!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} size="small" style={{width:"60%"}} min={"0"} max={"10"} defaultValue={TunnelTimes07!} onChange={(e)=>{SetTunnelTimes07(`${e}`)}} /></Col><Col span={16}><Text onChange={(e)=>{/*西隧*/}}>Western Harbour Crossing</Text></Col></Row>
        //         <Row><Col span={8}><InputNumber disabled={!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} size="small" style={{width:"60%"}} min={"0"} max={"10"} defaultValue={TunnelTimes08!} onChange={(e)=>{SetTunnelTimes08(`${e}`)}} /></Col><Col span={16}><Text onChange={(e)=>{/*大老山*/}}>Tate' s Cairn Tunnel</Text></Col></Row>
        //         <Row><Col span={8}><InputNumber disabled={!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} size="small" style={{width:"60%"}} min={"0"} max={"10"} defaultValue={TunnelTimes09!} onChange={(e)=>{SetTunnelTimes09(`${e}`)}} /></Col><Col span={16}><Text onChange={(e)=>{/*大欖*/}}>Tai Lam Tunnel</Text></Col></Row>
        //         <Row><Col span={8}><InputNumber disabled={!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} size="small" style={{width:"60%"}} min={"0"} max={"10"} defaultValue={TunnelTimes10!} onChange={(e)=>{SetTunnelTimes10(`${e}`)}} /></Col><Col span={16}><Text onChange={(e)=>{/*愉景灣*/}}>Discovery Bay Tunnel</Text></Col></Row>
        //         <Row><Col span={8}><InputNumber disabled={!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} size="small" style={{width:"60%"}} min={"0"} max={"10"} defaultValue={TunnelTimes11!} onChange={(e)=>{SetTunnelTimes11(`${e}`)}} /></Col><Col span={16}><Text onChange={(e)=>{/*青馬大橋*/}}>Tsing Ma Bridge</Text></Col></Row>
        //         <Row><Col span={8}><InputNumber disabled={!((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} size="small" style={{width:"60%"}} min={"0"} max={"10"} defaultValue={TunnelTimes12!} onChange={(e)=>{SetTunnelTimes12(`${e}`)}} /></Col><Col span={16}><Text onChange={(e)=>{/*將軍澳*/}}>Tseung Kwan O Tunnel</Text></Col></Row>
        //     </div>
        // )
    }
    const dialogAssignDriver = (
    <Modal title={""} open={isModalOpen1} onOk={()=>{}} onCancel={()=>{setIsModalOpen1(false)}} confirmLoading={confirmLoading}
    width={450}
    bodyStyle={{height:130}}
    footer={[

      <Button key="back" onClick={()=>{setIsModalOpen1(false)}}>
        Cancel
      </Button>,

      <Button key="submit" type="primary" loading={confirmLoading} onClick={
            ()=>{
              confirmDialog("Dispatch?","",
                ()=>{
                    ConsoleLog(DriverID+":"+DriverName+":"+DriverMobile+":"+VehicleID+":")
                    ConsoleLog(DriverID)
                    OrderDetailPageStatus.AssignDriverID = DriverID
                    ConsoleLog(DriverID)
                    ConsoleLog(OrderDetailPageStatus.AssignDriverID)
                    OrderDetailPageStatus.DriverName = DriverName
                    OrderDetailPageStatus.DriverMobile = DriverMobile
                    OrderDetailPageStatus.AssignVehicleID = VehicleID
                    OrderDetailPageStatus.Status = "Dispatched"
                    //SetStatus("Dispatched")
                    //SetOrderDetailPageStatus({...OrderDetailPageStatus})
                    saveOrderDetailPageStatus()
                    setIsModalOpen1(false);
                    UpdateOrder(false,false);  
                    if(OrderDetailPageStatus.SMSEnable === "1"){
                        ResendOrderSMS(OrderDetailPageStatus.OrderNo,"Redispatch")
                    }
                },
                ()=>{
                  //setIsModalOpen1(false);
                })
            }
          }
        >
        Assign
      </Button>,
    ]}

    >
      <div style={{height:130,overflow:"hidden",overflowX:"hidden"}}>
        <Row>
            <Col span={11}><Text style={{  paddingTop: 5,width: "95%" } } >Assigned Driver</Text></Col>
            <Col span={13}>
            <AutoComplete style={{ width: "90%" } } disabled={false}
                value = {DriverName} 
                
                onSelect={(e)=>{
                    // //SetDriverID(e);
                    // SetDriverName(e);
                    // //ConsoleLog(e+":"+DriverFilterDetailList.toString());
                    // //OrderDetailPageStatus.AssignDriverID = (e)
                    // const listsize = DriverFilterDetailList.length
                    // for(var k = 0 ; k<listsize ; k++){
                    //     if(e===DriverFilterDetailList[k]["Name"]){
                    //         //OrderDetailPageStatus.DriverName = (DriverDetailList[k]["Name"])
                    //         //OrderDetailPageStatus.DriverMobile = (DriverDetailList[k]["DriverTelNo"])
                    //         SetDriverName(DriverFilterDetailList[k]["DriverID"])
                    //         SetDriverMobile(DriverFilterDetailList[k]["DriverTelNo"])
                    //         break
                    //     }
                    // }
                    SetDriverID(e);
                    ConsoleLog(e);
                    //OrderDetailPageStatus.AssignDriverID = (e)
                    const listsize = DriverFilterDetailList.length
                    for(var k = 0 ; k<listsize ; k++){
                        if(e===DriverFilterDetailList[k]["DriverID"]){
                            //OrderDetailPageStatus.DriverName = (DriverDetailList[k]["Name"])
                            //OrderDetailPageStatus.DriverMobile = (DriverDetailList[k]["DriverTelNo"])
                            SetDriverName(DriverFilterDetailList[k]["Name"])
                            SetDriverMobile(DriverFilterDetailList[k]["DriverTelNo"])
                            break
                        }
                    }
                }}

                onChange={(e)=>{
                    //SetDriverID(e);
                    SetDriverName(e);
                    //ConsoleLog(e+":"+DriverFilterDetailList.toString());
                    //OrderDetailPageStatus.AssignDriverID = (e)
                    const listsize = DriverFilterDetailList.length
                    for(var k = 0 ; k<listsize ; k++){
                        if(e===DriverFilterDetailList[k]["Name"]){
                            //OrderDetailPageStatus.DriverName = (DriverDetailList[k]["Name"])
                            //OrderDetailPageStatus.DriverMobile = (DriverDetailList[k]["DriverTelNo"])
                            SetDriverID(DriverFilterDetailList[k]["DriverID"])
                            SetDriverMobile(DriverFilterDetailList[k]["DriverTelNo"])
                            break
                        }
                        if((k) === (listsize -1 )){
                            SetDriverID("")
                            SetDriverMobile("")
                        }
                    }
                    // SetDriverID(e);
                    // ConsoleLog(e);
                    // //OrderDetailPageStatus.AssignDriverID = (e)
                    // const listsize = DriverFilterDetailList.length
                    // for(var k = 0 ; k<listsize ; k++){
                    //     if(e===DriverFilterDetailList[k]["DriverID"]){
                    //         //OrderDetailPageStatus.DriverName = (DriverDetailList[k]["Name"])
                    //         //OrderDetailPageStatus.DriverMobile = (DriverDetailList[k]["DriverTelNo"])
                    //         SetDriverName(DriverFilterDetailList[k]["Name"])
                    //         SetDriverMobile(DriverFilterDetailList[k]["DriverTelNo"])
                    //         break
                    //     }
                    // }
                }}

                filterOption={(inputValue, option) =>
                    option!.label.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
                    }
                placeholder=""
                //onSelect={(e)=>{ConsoleLog(e)}}
                options={DriverFilterList}/>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 } } >
            <Col span={11}><Text style={{  paddingTop: 5,width: "95%" } } >Driver Mobile</Text></Col>
            <Col span={13}>
            <InputNumber controls={false} value={DriverMobile} onChange={(e)=>{
                            SetDriverMobile(e!)
                        }} style={{ width: "90%" }}/>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 } } >
            <Col span={11}><Text style={{  paddingTop: 5,width: "95%" } } >Assigned Vehicle</Text></Col>
            <Col span={13}>
            <AutoComplete style={{ width: "90%" }}  disabled={false}
                value = {VehicleID} 
                onSelect={(e)=>{
                    SetVehicleID(e)
                    //OrderDetailPageStatus.AssignVehicleID = (e)
                }}
                onChange={(e)=>{
                    SetVehicleID(e)
                    //OrderDetailPageStatus.AssignVehicleID = (e)
                }}
                options={VehicleFilterList}/>
            </Col>
        </Row>
      </div>
  </Modal>
    );
    //+`?Date=${new Date().toISOString()}`
    const dialogAudit = <Modal title={AuditTrailTitle} open={IsAuditTrail} onOk={()=>{}} onCancel={()=>{setIsAuditTrail(false)}} confirmLoading={confirmLoading}
        width={1000}
        bodyStyle={{height:500}}
        footer={[

        <Button key="back" onClick={()=>{setIsAuditTrail(false)}}>
            Return
        </Button>,
        ]}

        >
            <Row style={{width:900,background:"#EEEEEE"}}>
                <Col span={4}><Text style={{fontWeight:"bold"}}>{"DateTime"}</Text></Col>
                <Col span={4}><Text style={{fontWeight:"bold"}}>{"System"}</Text></Col>
                <Col span={4}><Text style={{fontWeight:"bold"}}>{"UpdatedBy"}</Text></Col>
                <Col span={4} style={{fontWeight:"bold"}}>{"Change"}</Col>
                <Col span={4} style={{fontWeight:"bold"}}>{"Old"}</Col>
                <Col span={4} style={{fontWeight:"bold"}}>{"New"}</Col>
            </Row>
            <Row style={{marginTop:5,height:480,overflow:"scroll"}}>
                <Col span={24}>
                    {AuditTrailContent}
                </Col>
            </Row>
        </Modal>
    const uploadPhotoRow = (No)=>{
        return(
            <Row style={{  background:"#ffffff",borderRadius:10,border:" 2px solid #1677ff",padding:5,marginTop:5 } }>
            <Col span={12}>
                <Image style={{  background:"#ffffff",borderRadius:0,border:" 1px solid #676767",padding:1} } width={200} src={photoList[0][`Photo${No}`]===""?(webUrl.replace("#/","")+"nodocument.png"):(photoList[0][`Photo${No}`]+`?reload=${reloadCount[`P${No}`]}`)}/>
            </Col>
            <Col span={12}>
                <Row>
                <Col span={24}>
                    <input
                        ref={
                            No==="1"?updaloadFilePhoto01:
                            (No==="2"?updaloadFilePhoto02:
                            (No==="3"?updaloadFilePhoto03:
                            (No==="4"?updaloadFilePhoto04:
                            (No==="5"?updaloadFilePhoto05:
                            (No==="6"?updaloadFilePhoto06:
                            (No==="7"?updaloadFilePhoto07:
                            (No==="8"?updaloadFilePhoto08:
                            (No==="9"?updaloadFilePhoto09:
                            (No==="10"?updaloadFilePhoto10:null)))))))))
                        }
                        style={{ display: "none" }}
                        color="primary" 
                        type="file"
                        name="uploadRoute"
                        accept=".png, .jpg, .jpeg"
                        onChange={(event) => {
                            var type = ".png"
                            var file = event.target.files![0];
                            let reader = new FileReader();
                            if (file) {
                            reader.onload = function(){
                                let result = `${reader.result}`
                                if(result.includes("data:image/png;base64,")){
                                    type = ".png"
                                }else if(result.includes("data:image/jpg;base64,")){
                                    type = ".jpg"
                                }else if(result.includes("data:image/jpeg;base64,")){
                                    type = ".jpeg"
                                }else if(result.includes("data:image/bmp;base64,")){
                                    type = ".bmp"
                                }else{
                                    type = "null"
                                }
                                //ConsoleLog(result)
                                if(type != "null"){
                                    ImportImage(type,result.replace("data:image/png;base64,","").replace("data:image/jpg;base64,","").replace("data:image/jpeg;base64,","").replace("data:image/bmp;base64,",""),OrderDetailPageStatus.OrderNo!,No)
                                }else{
                                    oneButtonConfirmDialog("Unspported file is selected","",()=>{})
                                }
                            }
                            reader.readAsDataURL(file);
                            }

                        }}
                    />
                    <Button key="submit" type="primary" style={{  paddingTop: 0,width: "99%" } } disabled={!(photoList[0][`Photo${No}`] === "")} onClick={
                            ()=>{
                                if(No === "1"){
                                    updaloadFilePhoto01.current!.click()
                                }else if(No === "2"){
                                    updaloadFilePhoto02.current!.click()
                                }else if(No === "3"){
                                    updaloadFilePhoto03.current!.click()
                                }else if(No === "4"){
                                    updaloadFilePhoto04.current!.click()
                                }else if(No === "5"){
                                    updaloadFilePhoto05.current!.click()
                                }else if(No === "6"){
                                    updaloadFilePhoto06.current!.click()
                                }else if(No === "7"){
                                    updaloadFilePhoto07.current!.click()
                                }else if(No === "8"){
                                    updaloadFilePhoto08.current!.click()
                                }else if(No === "9"){
                                    updaloadFilePhoto09.current!.click()
                                }else if(No === "10"){
                                    updaloadFilePhoto10.current!.click()
                                }
                            }
                        }
                        >
                            Select & Upload File
                        </Button>
                    </Col>
                </Row>
                <Row style={{marginTop:3}}>
                    <Col span={24}>
                    <Button key="submit" type="primary" style={{  paddingTop: 0,width: "99%" } } disabled={(photoList[0][`Photo${No}`] === "")} onClick={
                            ()=>{
                                confirmDialog(`Delete Document ${No}?`,"",()=>{DeleteImage(OrderDetailPageStatus.OrderNo!,No)},()=>{})
                            }
                        }
                        >
                            Delete File
                    </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
        )
    }
    const dialogPhoto = (
        <Modal title={""} open={isModalOpen2} onOk={()=>{}} closable={false} onCancel={()=>{localStorage.setItem('isOpenPhoto',"false");setIsModalOpen2(false)}} confirmLoading={confirmLoading}
        width={500}
        bodyStyle={{height:500}}
        footer={[
            <Row>
                <Col span={18}>
                <Row><Text style={{fontSize:12,width:"100%",textAlign:"left"}}>* Document file format: JPG or PNG</Text></Row>
                </Col>
                <Col span={6}>
                    <Button key="back" onClick={()=>{localStorage.setItem('isOpenPhoto',"false");setIsModalOpen2(false)}}>
                        Close
                    </Button>
                </Col>
            </Row>,
        ]}
    
        >
          <div style={{height:500,overflowY:"hidden",overflowX:"hidden"}}>
            <Text style={{height:47,fontSize:18,fontWeight:"bold"}}>Supporting Documents</Text>
            <div style={{height:3,width:"100%",background:"#1677ff"}}></div>
            <div style={{height:450,overflowY:"scroll",overflowX:"hidden"}}>
            {uploadPhotoRow("1")}
            {uploadPhotoRow("2")}
            {uploadPhotoRow("3")}
            {uploadPhotoRow("4")}
            {uploadPhotoRow("5")}
            {uploadPhotoRow("6")}
            {uploadPhotoRow("7")}
            {uploadPhotoRow("8")}
            {uploadPhotoRow("9")}
            {uploadPhotoRow("10")}
            </div>
          </div>
      </Modal>
    );
    const CopyButton_ = <Button type="primary" hidden={true} 
    style={{width:"98%"}}
    onClick={()=>{
        confirmDialog("Copy Record?","",
        ()=>{
        //setConfirmLoading(true);
        setTimeout(() => {
            SetActualOrderPickUpDate(new Date().toISOString().substring(0,10));
            OrderDetailPageStatus["Status"] = "Open";
            SetOrderDetailPageStatus({...OrderDetailPageStatus})
            UpdateOrder(true,false);
        }, 500);    
        },
        ()=>{
        //setIsModalOpen1(false);
    })

    }}>
        Copy
     </Button>

    const CopyButton = null

    const AuditButton = <Button type="primary"
    style={{width:"98%"}}
    onClick={()=>{
        GetAuditTrailByOrderNo(OrderDetailPageStatus["OrderNo"])
    }}>
        Audit
    </Button>

    const CloseButton = (Status?.includes("Uploaded"))?null:<Button type="primary" 
        style={{width:"98%"}}
        onClick={()=>{
            if(Status?.includes("Uploaded")){
                window.close()
            }else{
                confirmDialog2("Save the Record?","",
                "Yes",
                "No",
                ()=>{
                    SetOrderDetailPageStatus({...OrderDetailPageStatus})
                    setTimeout(() => {
                        UpdateOrder(false,true);
                    }, 500);   
                },
                ()=>{
                    window.close()
                })
            }
        }}>
        Close
    </Button>
//ConsoleLog("Save:"+(Status?.includes("Uploaded")))
    const SaveButton = (Status?.includes("Uploaded"))?<Button type="primary" 
    style={{width:"98%"}}
    onClick={()=>{
        window.close()
    }}>
    Close
</Button>:<Button type="primary" htmlType="submit"
    style={{width:"98%"}}
    onClick={()=>{
        UpdateOrder(false,false);
    }}>
    Save
</Button>

    const ChangeStatus=(StatusName,StatusString)=>{
        confirmDialog2(`Change Order Status to "${StatusName}" ?`,"","Yes","Cancel",
        ()=>{
            OrderDetailPageStatus.Status = StatusString
            saveOrderDetailPageStatus()
            UpdateOrder(false,false);                    
        },
        ()=>{
          //setIsModalOpen1(false);
        })
    }
/*
            {`${orderStatusReferenceIndexMap[OrderDetailPageStatus.Status!]}`.includes(" Uploaded")?
                (
                    <Row>
                    <Text style={{fontSize:20,width:"35%"}}></Text>
                    <Text style={{paddingLeft:5,paddingTop:5,fontSize:15,width:"53%",background:"#DDDDDD"}}>Uploaded</Text>
                </Row>
                ):null
            }
*/
    const dialog_element00_00 = (
    <Row>
        <Col span={13}>
            <Row>
                <Text style={{fontSize:20,width:"30%"}}>Status</Text>
                <Text style={{paddingLeft:5,paddingTop:5,fontSize:15,width:"69%",background:"#DDDDDD"}}>{
                    `${orderStatusReferenceIndexMap[OrderDetailPageStatus.Status!]}`
                }</Text>
            </Row>
        </Col>
        <Col span={11}>
                {(Status === "Dropoff" || Status === "Noshow" || Status === "Cancel")?(<Checkbox style={{marginTop:0,paddingLeft:10}} defaultChecked={OrderDetailPageStatus.ReadyToUpload === "1"} onChange={(e)=>{OrderDetailPageStatus.ReadyToUpload = (e.target.checked)?"1":"0";SetOrderDetailPageStatus({...OrderDetailPageStatus})}}>Ready to Upload</Checkbox>):null}
                {!(Status?.includes("Fail") || Status?.includes("Upload") || Status === "Dropoff" || Status === "Noshow" || Status === "Cancel")?(<Checkbox style={{marginTop:5,paddingLeft:10}} checked={OrderDetailPageStatus.SMSEnable === "1"} onChange={(e)=>{
                    if(OrderDetailPageStatus.AssignDriverID === "" ||
                    OrderDetailPageStatus.AssignVehicleID === ""){
                        oneButtonConfirmDialog("Please assign Driver and Vehicle","",()=>{});
                    }else{
                        OrderDetailPageStatus.SMSEnable = (e.target.checked)?"1":"0";
                        SetOrderDetailPageStatus({...OrderDetailPageStatus})
                    }
                }}>Auto SMS Notification</Checkbox>):null}
                {Status?.includes("Fail")?(<Checkbox style={{marginTop:5,paddingLeft:10}} defaultChecked={OrderDetailPageStatus.ReadyToUpload === "1"} onChange={(e)=>{OrderDetailPageStatus.ReadyToUpload = (e.target.checked)?"1":"0";SetOrderDetailPageStatus({...OrderDetailPageStatus})}}>Re-upload</Checkbox>):null}
        </Col>
        <Col span={0}>

        </Col>
        <Col span={0}>

        </Col>
    </Row>
    )
//May change list by ststus?
    const dialog_element00_01 = (<div>
        <Row style={{height:5}}></Row>
        <Row>
                <Col span={6}>
                {dialog_element00_00}
                </Col>
                <Col span={0}></Col>
                <Col span={4}>
                {Status?.includes("Uploaded")?null:(<Checkbox style={{marginTop:5}} disabled={Status?.includes("Uploaded")} defaultChecked={OrderDetailPageStatus.OrderLock === "1"} 
                                                        onChange={(e)=>{OrderDetailPageStatus.OrderLock = (e.target.checked)?"1":"0";
                                                        SetOrderDetailPageStatus({...OrderDetailPageStatus});
                                                        UpdateOrderLock(((e.target.checked)?"1":"0"));
                                                        }}>Lock Order</Checkbox>)}
                </Col>
                <Col span={4}>
                    <Text hidden={!(Status === "Dropoff" || Status === "Noshow" || Status === "Cancel")} style={{fontSize:20,paddingLeft:20,width:"96%"}}>Change Order Status</Text>
                </Col>
                <Col span={4}>
                {(Status === "Dropoff" || Status === "Noshow" || Status === "Cancel")?
                (<Select value = {OrderDetailPageStatus.Status} style={{width:"90%"}} onSelect={(e)=>{
                    if(e.toString() !== "---"){
                        OrderDetailPageStatus.Status = e;
                        SetOrderDetailPageStatus({...OrderDetailPageStatus});
                    }else{
                        OrderDetailPageStatus.Status = Status;
                        SetOrderDetailPageStatus({...OrderDetailPageStatus});
                    }
                }} options={
                        [
                            {label: "---", value: "---"},
                            {label: "Complete", value: "Dropoff"},
                            {label: "No show", value: "Noshow"},
                            {label: "Cancelled", value: "Cancel"},
                        ]
                }/>):null
                }
                </Col>
                <Col span={0}>
                    {CopyButton}
                </Col>

                <Col span={2}>
                    {AuditButton}
                </Col>

                <Col span={2}>
                    {CloseButton}
                </Col>
                <Col span={2}>
                    {SaveButton}
                </Col>
    </Row>
    <Row style={{height:5}}></Row>
    <Row style={{background:"#4F81BD",height:2}}></Row>
    </div>)

    const dialog_element00_02 = (<div>
        <Row style={{height:5}}></Row>
        <Row>
                <Col span={6}>
                {dialog_element00_00}
                </Col>
                <Col span={2}></Col>
                <Col span={5}>

                </Col>
                <Col span={1}>
                    {CopyButton}
                </Col>
                <Col span={2}>
                    <Button type="primary" 
                        style={{background:"#C0504D",width:"98%"}}
                        onClick={()=>{
                            //SetStatus("Cancel")
                            ChangeStatus("Cancelled","Cancel")
                        }}>
                        Cancel Order
                    </Button>
                </Col>
                <Col span={2}>
                    <Button type="primary" 
                        style={{background:"#77933C",width:"98%"}}
                        onClick={()=>{
                            setIsModalOpen1(true)
                        }}>
                        Dispatch
                    </Button>
                </Col>
                <Col span={2}>
                    {AuditButton}
                </Col>
                <Col span={2}>
                    {CloseButton}
                </Col>
                <Col span={2}>
                    {SaveButton}
                </Col>
    </Row>
    <Row style={{height:5}}></Row>
    <Row style={{background:"#4F81BD",height:2}}></Row>
    </div>)

    const dialog_element00_03 = (<div>
        <Row style={{height:5}}></Row>
        <Row>
                <Col span={6}>
                {dialog_element00_00}
                </Col>
                <Col span={2}></Col>
                <Col span={1}>

                </Col>
                <Col span={1}>
                    {CopyButton}
                </Col>
                <Col span={2}>
                    <Button type="primary" 
                        style={{background:"#604A7B",width:"98%"}}
                        onClick={()=>{
                            //SetStatus("Dropoff")
                            ChangeStatus("Completed","Dropoff")
                            // OrderDetailPageStatus.Status = "Dropoff"
                            // saveOrderDetailPageStatus()
                        }}>
                        Complete
                    </Button>
                </Col>
                <Col span={2}>
                    <Button type="primary" 
                        style={{background:"#77933C",width:"98%"}}
                        onClick={()=>{
                            setIsModalOpen1(true)
                        }}>
                        Re-Dispatch
                    </Button>
                </Col>
                <Col span={2}>
                    <Button type="primary" 
                        style={{background:"#C0504D",width:"98%"}}
                        onClick={()=>{
                            //SetStatus("Cancel")
                            // OrderDetailPageStatus.Status = "Cancel"
                            // saveOrderDetailPageStatus()
                            ChangeStatus("Cancelled","Cancel")
                        }}>
                        Cancel Order
                    </Button>
                </Col>
                <Col span={2}>
                    <Button type="primary" 
                        style={{background:"#E46C0A",width:"98%"}}
                        onClick={()=>{
                            //SetStatus("Noshow")
                            // OrderDetailPageStatus.Status = "Noshow"
                            // saveOrderDetailPageStatus()
                            ChangeStatus("No Show","Noshow")
                        }}>
                        No Show
                    </Button>
                </Col>
                <Col span={2}>
                    {AuditButton}
                </Col>
                <Col span={2}>
                    {CloseButton}
                </Col>
                <Col span={2}>
                    {SaveButton}
                </Col>
    </Row>
    <Row style={{height:5}}></Row>
    <Row style={{background:"#4F81BD",height:2}}></Row>
    </div>)

    const dialog_element00_04 = (<div>
        <Row style={{height:5}}></Row>
        <Row>
                <Col span={7}>
                {dialog_element00_00}
                </Col>
                <Col span={2}></Col>
                <Col span={7}>

                </Col>
                <Col span={2}>
                    {CopyButton}
                </Col>
                <Col span={2}>
                    <Button type="primary" 
                        style={{background:"#604A7B",width:"98%"}}
                        onClick={()=>{
                            //SetStatus("Dropoff")
                            // OrderDetailPageStatus.Status = "Dropoff"
                            // saveOrderDetailPageStatus()
                            ChangeStatus("Completed","Dropoff")
                        }}>
                        Complete
                    </Button>
                </Col>

                <Col span={2}>
                    {CloseButton}
                </Col>
                <Col span={2}>
                    {SaveButton}
                </Col>
    </Row>
    <Row style={{height:5}}></Row>
    <Row style={{background:"#4F81BD",height:2}}></Row>
    </div>)
//Journey === "Hourly Rental"?false:true
    const dialog_element01 = (<div>
            <Row>
                <Col span={4}><Text>Order No</Text></Col>
                <Col span={4}><Text>*Order Date</Text></Col>
                <Col span={4}><Text>E-form Order No</Text></Col>
                <Col span={3}><Text>Customer Type</Text></Col>
                <Col span={3}><Text>Vehicle Class</Text></Col>
                <Col span={4}><Text>Journey</Text></Col>
                <Col span={2}><Text>Hour Rental</Text></Col>
            </Row>

            <Row>
                <Col span={4}><Input disabled={true} defaultValue={OrderDetailPageStatus.OrderNo!} onChange={(e)=>{OrderDetailPageStatus.OrderNo = (e.target.value)}} style={{ width: "98%" }}/></Col>
                <Col span={4}><DatePicker disabled={true} defaultValue={dayjs(`${BookingDatetime!.length >=10 ? BookingDatetime!.substring(0,10):TolocalDateString(new Date().toISOString()).substring(0,10)}`,'YYYY-MM-DD')} format={"YYYY-MM-DD"} style={{ width: "98%" }}/></Col>
                <Col span={4}><Input disabled={true} defaultValue={OrderDetailPageStatus.EformOrderNo!} onChange={(e)=>{OrderDetailPageStatus.EformOrderNo = (e.target.value)}} style={{ width: "98%" }}/></Col>
                <Col span={3}>
                    <Select style={{ width: "98%" }} disabled={true} defaultValue={OrderDetailPageStatus.CustomerType} onChange={(e)=>{OrderDetailPageStatus.CustomerType = (e)}}>
                    <Select.Option value="Corporate">Corporate</Select.Option>
                    <Select.Option value="Personal">Personal</Select.Option>
                    <Select.Option value="Motor Club">Motor Club</Select.Option>
                    <Select.Option value="DCH Staff">DCH Staff</Select.Option>
                    </Select>
                </Col>
                <Col span={3}>
                    <Select style={{ width: "98%" }} disabled={true} defaultValue = {OrderDetailPageStatus.CarType} onChange={(e)=>{OrderDetailPageStatus.CarType = (e)}}>
                    <Select.Option value="Benz S">Benz S</Select.Option>
                    <Select.Option value="Benz EQE">Benz EQE</Select.Option>
                    <Select.Option value="6-seater MPV">6-seater MPV</Select.Option>
                    <Select.Option value="28-seater Coach">28-seater Coach</Select.Option>
                    <Select.Option value="49-seater">49-seater</Select.Option>
                    <Select.Option value="60-seater">60-seater</Select.Option>
                    </Select>
                </Col>
                <Col span={4}>
                    <Select style={{ width: "98%" }} disabled={true} defaultValue = {OrderDetailPageStatus.Journey} onChange={(e)=>{if(e === "Hourly Rental"){SetEstimatedOrderDuration("0");OrderDetailPageStatus.EstimatedOrderDuration = ("0");}else{};SetJourney(e);OrderDetailPageStatus.Journey = (e)}}>
                    <Select.Option value="Point Point Transfer">Point Point Transfer</Select.Option>
                    <Select.Option value="Hourly Rental">Hourly Rental</Select.Option>
                    <Select.Option value="Cross Border">Cross Border</Select.Option>
                    </Select>
                </Col>
                <Col span={2}>
                    <InputNumber disabled={true} value={EstimatedOrderDuration} onChange={(e)=>{SetEstimatedOrderDuration(e!);OrderDetailPageStatus.EstimatedOrderDuration = (e!)}} style={{ width: "98%" }}/>
                </Col>
            </Row>
    </div>)
    const dialog_element02 = (<div>
            <Row>
                <Col span={15}><Text>Customer Name</Text></Col>
                <Col span={9}><Text>Contact Person</Text></Col>
            </Row>

            <Row>
                <Col span={15}><Text><Input disabled={true} defaultValue={OrderDetailPageStatus.CustomerName!} onChange={(e)=>{OrderDetailPageStatus.CustomerName = (e.target.value)}} style={{ width: "98%" }}/></Text></Col>
                <Col span={9}><Text><Input disabled={true} defaultValue={OrderDetailPageStatus.ContactName1!} onChange={(e)=>{OrderDetailPageStatus.ContactName1 = (e.target.value)}} style={{ width: "98%" }}/></Text></Col>
            </Row>
    </div>)
    const dialog_element03 = (<div>
            <Row>
                <Col span={16}><Text>Customer Address</Text></Col>
                <Col span={4}><Text>Contact Tel</Text></Col>
                <Col span={4}><Text>Contact Mobile</Text></Col>
            </Row>

            <Row>
                <Col span={16}><Text><Input disabled={true} defaultValue={OrderDetailPageStatus.Address!} onChange={(e)=>{OrderDetailPageStatus.Address = (e.target.value)}} style={{ width: "98%" }}/></Text></Col>
                <Col span={4}><InputNumber disabled={true} defaultValue={OrderDetailPageStatus.ContactTelNo1!} onChange={(e)=>{OrderDetailPageStatus.ContactTelNo1 = (e!)}} style={{ width: "98%" }}/></Col>
                <Col span={4}><InputNumber disabled={false} controls={false} defaultValue={OrderDetailPageStatus.ContactTelNo2!} onChange={(e)=>{OrderDetailPageStatus.ContactTelNo2 = (e!)}} style={{ width: "98%" }}/></Col>
            </Row>
    </div>)
    const dialog_element04 = (<div>
            <Row>
                <Col span={14}><Text>Passenger Name</Text></Col>
                <Col span={2}><Text>Title</Text></Col>
                <Col span={4}><Text>Passenger Mobile</Text></Col>
                <Col span={2}><Text>Pax No</Text></Col>
                <Col span={2}><Text>Luggage</Text></Col>
            </Row>

            <Row>
                <Col span={14}><Input disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} defaultValue={OrderDetailPageStatus.PassengerName!} onChange={(e)=>{OrderDetailPageStatus.PassengerName = (e.target.value)}} style={{ width: "98%" }}/></Col>
                <Col span={2}>
                    <Select disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} style={{ width: "95%" }} defaultValue = {OrderDetailPageStatus.Title} onChange={(e)=>{OrderDetailPageStatus.Title = (e)}}>
                    <Select.Option value="MR">MR</Select.Option>
                    <Select.Option value="MRS">MRS</Select.Option>
                    </Select>
                </Col>
                <Col span={4}><InputNumber disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} controls={false} defaultValue={OrderDetailPageStatus.PassengerTelNo!} onChange={(e)=>{OrderDetailPageStatus.PassengerTelNo = (e!)}} style={{ width: "98%" }}/></Col>
                <Col span={2}><InputNumber disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} defaultValue={OrderDetailPageStatus.NoOfPassenger!} onChange={(e)=>{OrderDetailPageStatus.NoOfPassenger = (e!)}} style={{ width: "95%" }}/></Col>
                <Col span={2}><InputNumber disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} defaultValue={OrderDetailPageStatus.NoOfLuggage!} onChange={(e)=>{OrderDetailPageStatus.NoOfLuggage = (e!)}} style={{ width: "95%" }}/></Col>
            </Row>
    </div>)
    const dialog_element05 = (<div>
            <Row style={{background:"#ffffff",borderRadius:10,border:" 2px solid #1677ff",padding:5,marginTop:5}}>
                <Col span={24}>
                    <Row>
                        <Col span={3}>
                            <Tooltip placement="rightBottom" title={
                                <>
                                    <div style={{fontSize:15,fontWeight:"bold"}}>Original Pick-up Date</div>
                                    <div>{OrderPickUpDate}</div>
                                </>
                            }><Text>Pick-up Date</Text></Tooltip>
                        </Col>
                        <Col span={3}>
                            <Tooltip placement="rightBottom" title={
                                <>
                                    <div style={{fontSize:15,fontWeight:"bold"}}>Original Pick-up Time</div>
                                    <div>{OrderPickUpTime}</div>
                                </>
                            }><Text>Pick-up Time</Text></Tooltip>
                        </Col>
                        <Col span={3}>
                            <Tooltip placement="rightBottom" title={
                                <>
                                    <div style={{fontSize:15,fontWeight:"bold"}}>Original Flight No</div>
                                    <div>{FlightNo}</div>
                                </>
                            }><Text>Flight No</Text></Tooltip>
                        </Col>
                        <Col span={3}><Text>Flight Schedule</Text></Col>
                        <Col span={6}><Text>Status</Text></Col>
                        <Col span={3}><Text>Service Provider</Text></Col>
                        <Col span={3}><Text>Vendor Name</Text></Col>
                    </Row>
                    <Row>
                        <Col span={3}><DatePicker disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} defaultValue={dayjs(`${ActualOrderPickUpDate}`,'YYYY-MM-DD')} onChange={(e)=>{if(e !== null){SetActualOrderPickUpDate(TolocalDateString(e?.toISOString()).substring(0,10))}}} style={{ width: "95%",marginTop:5}}/></Col>
                        {/* <Col span={0}><TimePicker disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} 
                            defaultValue={dayjs(`${ActualOrderPickUpTime}`,'HH:mm')} 
                            format="HH:mm" 
                            onChange={(e)=>{
                                if(e !== null){SetActualOrderPickUpTime(TolocalDateString(e?.toISOString()).substring(11,16))}
                            }} style={{ width: "95%",marginTop:5 }}/>
                        </Col> */}
                        <Col span={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimeField
                                    disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} 
                                    size='small'
                                    inputProps={{style: { paddingTop:5,paddingBottom:5,paddingLeft:10,fontSize:15,textAlign: 'left' }}}
                                    style={{marginTop:5,width:"98%"}}
                                    value={dayjs(`${
                                        ActualOrderPickUpTime
                                    }`,
                                    'HH:mm')}
                                    onChange={
                                        (e)=>{
                                            ConsoleLog(`${e}`)
                                            if((`${e}` !== "Invalid Date") && (`${e}` !== "null")){
                                                //ConsoleLog(TolocalDateString(e?.toISOString()))
                                                SetActualOrderPickUpTime(TolocalDateString(e?.toISOString()).substring(11,16));
                                            }
                                        }
                                    }
                                    format="HH:mm"
                                    />
                            </LocalizationProvider>
                        </Col>
                        <Col span={3}>
                            <AutoComplete disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} 
                                //defaultValue={OrderDetailPageStatus.FlightNo}
                                value={OrderDetailPageStatus.FlightNo}
                                options={FlightNoList}
                                style={{ width: "95%",marginTop:5 }}
                                onSelect={(e)=>{//ConsoleLog(e);
                                    SetActualFlightNo(FlightInfoList[parseInt(e)].FlightNo);
                                    SetFlightSchedule(FlightInfoList[parseInt(e)].FlightTime)
                                    SetFlightStatus(FlightInfoList[parseInt(e)].Status)

                                    OrderDetailPageStatus.FlightNo = (FlightInfoList[parseInt(e)].FlightNo);
                                    OrderDetailPageStatus.FlightSchedule = (FlightInfoList[parseInt(e)].FlightTime);
                                    OrderDetailPageStatus.FlightStatus = (FlightInfoList[parseInt(e)].Status);
                                }}
                                onChange={(e)=>{
                                    SetActualFlightNo(e);
                                    OrderDetailPageStatus.FlightNo = (e);
                                    var isExist = false
                                    const listLength = FlightInfoList.length
                                    for(var k=0;k<listLength;k++){
                                        if(e.toString().toUpperCase() === FlightInfoList[k].FlightNo.toString().toUpperCase()){
                                            SetFlightSchedule(FlightInfoList[k].FlightTime)
                                            SetFlightStatus(FlightInfoList[k].Status)
                                            OrderDetailPageStatus.FlightSchedule = (FlightInfoList[k].FlightTime)
                                            OrderDetailPageStatus.FlightStatus = (FlightInfoList[k].Status);
                                            isExist = true
                                            break
                                        }
                                    }
                                    if(!isExist){
                                        SetFlightSchedule("")
                                        SetFlightStatus("")
                                        OrderDetailPageStatus.FlightSchedule = ("0001-01-01 00:00:00")
                                        OrderDetailPageStatus.FlightStatus = ("---");
                                    }
                                }}
                                filterOption={(inputValue, option) =>
                                    option!.label.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
                                    }
                                placeholder=""
                            />
                            </Col>
                        <Col span={3}><Input disabled={true} defaultValue={FlightSchedule!} value={FlightSchedule!} onChange={(e)=>{SetFlightSchedule(e.target.value)}} style={{ width: "95%",marginTop:5 }}/></Col>
                        <Col span={6}><Input disabled={true} defaultValue={FlightStatus!.replace("---","")} value={FlightStatus!.replace("---","")} onChange={(e)=>{SetFlightStatus(e.target.value)}} style={{ width: "95%",marginTop:5 }}/></Col>
                        <Col span={3}>
                        <AutoComplete disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} 
                                //defaultValue={OrderDetailPageStatus.FlightNo}
                                value={OrderDetailPageStatus.ServiceProvider!}
                                options={
                                    ServiceProviderList
                                }
                                style={{ width: "95%",marginTop:5 }}
                                onSelect={(e)=>{//ConsoleLog(e);
                                    
                                    OrderDetailPageStatus.ServiceProvider = (e)
                                    if(e === "DCH"){
                                        OrderDetailPageStatus.VendorName = ""
                                        SetServiceVenderListfiltered([])
                                        SetVehicleFilterList(VehicleList)
                                    }else if(e === "Consignment"){
                                        SetServiceVenderListfiltered(ServiceVenderList)
                                        SetVehicleFilterList([])
                                    }
                                    SetOrderDetailPageStatus({...OrderDetailPageStatus})

                                    var selectItemList:SelectOption[] = []
                                    var list = []
                                    ConsoleLog(DriverDetailList)
                                    for(var k = 0;k<DriverDetailList.length;k++){//Name DriverID
                                        if(e === "DCH" && DriverDetailList[k]['ServiceProvider'] === "DCH"){
                                            ConsoleLog(1)
                                            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                                            list.push(DriverDetailList[k])
                                        }else if(e === "Consignment" && DriverDetailList[k]['ServiceProvider'] === "Consignment" && (DriverDetailList[k]['Vendor'] === OrderDetailPageStatus.VendorName || OrderDetailPageStatus.VendorName === "")){// || DriverDetailList[k]['Vendor'] === ""
                                            ConsoleLog(2)
                                            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                                            list.push(DriverDetailList[k])
                                        }
                                    }
                                    SetDriverFilterDetailList(list)
                                    SetDriverFilterList(selectItemList)
                                    //;saveOrderDetailPageStatus()
                                    SetDriverName("")
                                    SetDriverID("")
                                    SetVehicleID("")
                                    SetDriverMobile("")
                                    OrderDetailPageStatus.AssignDriverID = ""
                                    OrderDetailPageStatus.DriverName = ""
                                    OrderDetailPageStatus.DriverMobile = ""
                                    OrderDetailPageStatus.AssignVehicleID = ""
                                    saveOrderDetailPageStatus()
                                }}
                                onChange={(e)=>{
                                    OrderDetailPageStatus.ServiceProvider = (e)
                                    if(e === "DCH"){
                                        OrderDetailPageStatus.VendorName = ""
                                        SetServiceVenderListfiltered([])
                                        SetVehicleFilterList(VehicleList)
                                    }else if(e === "Consignment"){
                                        SetServiceVenderListfiltered(ServiceVenderList)
                                        SetVehicleFilterList([])
                                    }
                                    SetOrderDetailPageStatus({...OrderDetailPageStatus})
                                    //;saveOrderDetailPageStatus()
                                    var selectItemList:SelectOption[] = []
                                    var list = []
                                    ConsoleLog(DriverDetailList)
                                    for(var k = 0;k<DriverDetailList.length;k++){
                                        if(e === "DCH" && DriverDetailList[k]['ServiceProvider'] === "DCH"){
                                            ConsoleLog(1)
                                            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                                            list.push(DriverDetailList[k])
                                        }else if(e === "Consignment" && DriverDetailList[k]['ServiceProvider'] === "Consignment" && DriverDetailList[k]['Vendor'] === OrderDetailPageStatus.VendorName){// || DriverDetailList[k]['Vendor'] === ""
                                            ConsoleLog(2)
                                            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                                            list.push(DriverDetailList[k])
                                        }
                                    }
                                    SetDriverFilterDetailList(list)
                                    SetDriverFilterList(selectItemList)

                                    SetDriverName("")
                                    SetDriverID("")
                                    SetVehicleID("")
                                    SetDriverMobile("")
                                    OrderDetailPageStatus.AssignDriverID = ""
                                    OrderDetailPageStatus.DriverName = ""
                                    OrderDetailPageStatus.DriverMobile = ""
                                    OrderDetailPageStatus.AssignVehicleID = ""
                                    saveOrderDetailPageStatus()
                                }}
                                filterOption={(inputValue, option) =>
                                    option!.label.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
                                    }
                                placeholder=""
                            /></Col>
                        <Col span={3}>
                        <AutoComplete disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || (OrderDetailPageStatus.ServiceProvider === "DCH")} 
                                //defaultValue={OrderDetailPageStatus.FlightNo}
                                value={OrderDetailPageStatus.VendorName!}
                                options={
                                    ServiceVenderListfiltered
                                }
                                style={{ width: "95%",marginTop:5 }}
                                onSelect={(e)=>{//ConsoleLog(e);                                    
                                    OrderDetailPageStatus.VendorName = (e)
                                    SetOrderDetailPageStatus({...OrderDetailPageStatus})
                                    //;saveOrderDetailPageStatus()
                                    var selectItemList:SelectOption[] = []
                                    var list = []
                                    for(var k = 0;k<DriverDetailList.length;k++){
                                        if(OrderDetailPageStatus.ServiceProvider === "Consignment" && DriverDetailList[k]['ServiceProvider'] === "Consignment" && (DriverDetailList[k]['Vendor'] === e)){//  || DriverDetailList[k]['Vendor'] === ""
                                            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                                            list.push(DriverDetailList[k])
                                        }
                                    }
                                    SetDriverFilterDetailList(list)
                                    SetDriverFilterList(selectItemList)

                                    SetDriverName("")
                                    SetDriverID("")
                                    SetVehicleID("")
                                    SetDriverMobile("")
                                    OrderDetailPageStatus.AssignDriverID = ""
                                    OrderDetailPageStatus.DriverName = ""
                                    OrderDetailPageStatus.DriverMobile = ""
                                    OrderDetailPageStatus.AssignVehicleID = ""
                                    saveOrderDetailPageStatus()
                                }}
                                onChange={(e)=>{
                                    OrderDetailPageStatus.VendorName = (e)
                                    SetOrderDetailPageStatus({...OrderDetailPageStatus})
                                    //;saveOrderDetailPageStatus()
                                    var selectItemList:SelectOption[] = []
                                    var list = []
                                    for(var k = 0;k<DriverDetailList.length;k++){
                                        if(OrderDetailPageStatus.ServiceProvider === "Consignment" && DriverDetailList[k]['ServiceProvider'] === "Consignment" && (DriverDetailList[k]['Vendor'] === e)){//  || DriverDetailList[k]['Vendor'] === ""
                                            selectItemList.push({label: DriverDetailList[k]['Name'], value: DriverDetailList[k]['DriverID']})
                                            list.push(DriverDetailList[k])
                                        }
                                    }
                                    SetDriverFilterDetailList(list)
                                    SetDriverFilterList(selectItemList)

                                    SetDriverName("")
                                    SetDriverID("")
                                    SetVehicleID("")
                                    SetDriverMobile("")
                                    OrderDetailPageStatus.AssignDriverID = ""
                                    OrderDetailPageStatus.DriverName = ""
                                    OrderDetailPageStatus.DriverMobile = ""
                                    OrderDetailPageStatus.AssignVehicleID = ""
                                    saveOrderDetailPageStatus()
                                }}
                                filterOption={(inputValue, option) =>
                                    option!.label.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
                                    }
                                placeholder=""
                            /></Col>
                    </Row>
                </Col>
            </Row>
    </div>)
    const dialog_element06 = (<div>
            <Row style={{background:"#ffffff",borderRadius:10,border:" 2px solid #1677ff",padding:5,marginTop:5}}>
                <Col span={0}>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col span={6}>
                            <Tooltip placement="rightBottom" title={
                                <>
                                    <div style={{fontSize:15,fontWeight:"bold"}}>Original Pick-up Location</div>
                                    <div>{Pickup}</div>
                                </>
                            }><Text>Pick-up Location</Text></Tooltip>
                        </Col>
                        <Col span={6}>
                            <Tooltip placement="rightBottom" title={
                                <>
                                    <div style={{fontSize:15,fontWeight:"bold"}}>Original Drop-off Location</div>
                                    <div>{Destination}</div>
                                </>
                            }><Text>Drop-off Location</Text></Tooltip>
                        </Col>
                        <Col span={4}>
                            <Tooltip placement="rightBottom" title={
                                <>
                                    <div style={{fontSize:15,fontWeight:"bold"}}>Original Stop-over 1</div>
                                    <div>{StopOver1}</div>
                                </>
                            }><Text>Stop-over 1</Text></Tooltip>
                        </Col>
                        <Col span={4}>
                            <Tooltip placement="rightBottom" title={
                                <>
                                    <div style={{fontSize:15,fontWeight:"bold"}}>Original Stop-over 2</div>
                                    <div>{StopOver2}</div>
                                </>
                            }><Text>Stop-over 2</Text></Tooltip>
                        </Col>
                        <Col span={4}>
                            <Tooltip placement="rightBottom" title={
                                <>
                                    <div style={{fontSize:15,fontWeight:"bold"}}>Original Stop-over 3</div>
                                    <div>{StopOver3}</div>
                                </>
                            }><Text>Stop-over 3</Text></Tooltip>
                        </Col>
                    </Row>
                    <Row>
                    <Col span={6}><TextArea disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} rows={4} defaultValue={OrderDetailPageStatus.Pickup!} onChange={(e)=>{OrderDetailPageStatus.Pickup = (e.target.value)}} style={{ width: "95%", resize: 'none' }}/></Col>
                    <Col span={6}><TextArea disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} rows={4} defaultValue={OrderDetailPageStatus.Destination!} onChange={(e)=>{OrderDetailPageStatus.Destination = (e.target.value)}} style={{ width: "95%", resize: 'none' }}/></Col>
                    <Col span={4}><TextArea disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} rows={4} defaultValue={OrderDetailPageStatus.StopOver1!} onChange={(e)=>{OrderDetailPageStatus.StopOver1 = (e.target.value)}} style={{ width: "95%", resize: 'none' }}/></Col>
                    <Col span={4}><TextArea disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} rows={4} defaultValue={OrderDetailPageStatus.StopOver2!} onChange={(e)=>{OrderDetailPageStatus.StopOver2 = (e.target.value)}} style={{ width: "95%", resize: 'none' }}/></Col>
                    <Col span={4}><TextArea disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} rows={4} defaultValue={OrderDetailPageStatus.StopOver3!} onChange={(e)=>{OrderDetailPageStatus.StopOver3 = (e.target.value)}} style={{ width: "95%", resize: 'none' }}/></Col>
                    </Row>
                </Col>
            </Row>
    </div>)
    const dialog_element07 = (<div>
            <Row style={{background:"#ffffff",borderRadius:10,border:" 2px solid #1677ff",padding:5,marginTop:5}}>
                <Col span={24}>
                <Row>
                    <Col span={3}><Text>Assigned Driver</Text></Col>
                    <Col span={3}><Text>Assigned Vehicle</Text></Col>
                    <Col span={3}><Text>Driver Name</Text></Col>
                    <Col span={3}><Text>Driver Mobile</Text></Col>
                    <Col span={3}><Text>Reminder</Text></Col>
                    <Col span={9}><Text>Dispatch Remarks</Text></Col>
                </Row>

                <Row>
                    <Col span={3}>
                    <Input style={{ width: "95%" } } disabled={true}
                        value = {OrderDetailPageStatus.DriverName!} //.DriverName
                        onChange={(e)=>{
                            // OrderDetailPageStatus.AssignDriverID = (e)
                            // const listsize = DriverDetailList.length
                            // for(var k = 0 ; k<listsize ; k++){
                            //     if(e===DriverDetailList[k]["DriverID"]){
                            //         OrderDetailPageStatus.DriverName = (DriverDetailList[k]["Name"])
                            //         OrderDetailPageStatus.DriverMobile = (DriverDetailList[k]["DriverTelNo"])
                            //         SetDriverName(DriverDetailList[k]["Name"])
                            //         SetDriverMobile(DriverDetailList[k]["DriverTelNo"])
                            //         break
                            //     }
                            // }
                        }}
                        //onSelect={(e)=>{ConsoleLog(e)}}
                        //options={DriverList}
                        />
                    </Col>
                    <Col span={3}>
                    <Select style={{ width: "95%" }}  disabled={true}
                        value = {OrderDetailPageStatus.AssignVehicleID} 
                        onChange={(e)=>{
                            //OrderDetailPageStatus.AssignVehicleID = (e)
                        }}
                        options={VehicleList}/>
                    </Col>
                    <Col span={3}><Input disabled={true || OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || (Status === "Dropoff" || Status === "Cancel" || Status === "Noshow") } value={OrderDetailPageStatus.DriverName!} onChange={(e)=>{
                            OrderDetailPageStatus.DriverName = (e.target.value)
                            SetDriverName(e.target.value)
                            SetOrderDetailPageStatus({...OrderDetailPageStatus})
                        }} style={{ width: "98%" }}/></Col>
                    <Col span={3}><InputNumber disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || (Status === "Dropoff" || Status === "Cancel" || Status === "Noshow") } controls={false} value={OrderDetailPageStatus.DriverMobile} onChange={(e)=>{
                            OrderDetailPageStatus.DriverMobile = (e!)
                            SetDriverMobile(e!)
                        }} style={{ width: "98%" }}/></Col>
                    <Col span={3}><Input disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} defaultValue={OrderDetailPageStatus.FirstReminder!} onChange={(e)=>{
                        OrderDetailPageStatus.FirstReminder = (e.target.value)
                        }} style={{ width: "95%" }}/></Col>
                    <Col span={9}><Input disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded")} defaultValue={OrderDetailPageStatus.SpecialInstructions!} onChange={(e)=>{OrderDetailPageStatus.SpecialInstructions = (e.target.value)}} style={{ width: "98%" }}/></Col>
                </Row>
                </Col>
            </Row>
    </div>)
    const waitTime = ()=>{
        var min = (parseInt(DriverWaitDuration!) - parseInt(DriverWaitDuration!)%60)/60
        var sec = parseInt(DriverWaitDuration!)%60

        return min+"m"+ "" +sec+"s" 
    }
    const dialog_element08 = (<div>
            <Row style={{marginTop:5}}>
                <Col span={10}>
                <Row>
                    <Col span={12}><Text>Customer Request</Text></Col>
                    <Col span={12}><Text>Customer Remarks</Text></Col>
                </Row>
                <Row>
                    <Col span={12}><TextArea rows={4} disabled={true} defaultValue={OrderDetailPageStatus.HireRequest!} onChange={(e)=>{OrderDetailPageStatus.HireRequest = (e.target.value)}} style={{ width: "95%", resize: 'none' }}/></Col>
                    <Col span={12}><TextArea rows={4} disabled={true} defaultValue={OrderDetailPageStatus.Remarks!} onChange={(e)=>{OrderDetailPageStatus.Remarks = (e.target.value)}}style={{ width: "95%", resize: 'none' }}/></Col>
                </Row>
                </Col>
                <Col span={4}>
                <Row>
                    <Col span={12}><Text>Order Charge</Text></Col>
                    <Col span={12}><Text>Parking Fee</Text></Col>
                </Row>
                <Row>
                    <Col span={12}><InputNumber disabled={true} defaultValue = {OrderDetailPageStatus.OrderCharge!} onChange={(e)=>{OrderDetailPageStatus.OrderCharge = (e!)}}style={{ width: "95%",marginTop:5 }}/></Col>
                    <Col span={12}><InputNumber disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || !((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel"))} defaultValue = {OrderDetailPageStatus.ParkingCharge!} onChange={(e)=>{OrderDetailPageStatus.ParkingCharge = (e!)}}style={{ width: "95%",marginTop:5 }}/></Col>
                </Row>
                <Row>
                    <Col span={12}><Text>Add. Charge</Text></Col>
                    <Col span={12}><Text>Tunnel Fee</Text></Col>
                </Row>
                <Row>
                    <Col span={12}><InputNumber disabled={true} defaultValue = {OrderDetailPageStatus.AdditionalCharge!} onChange={(e)=>{OrderDetailPageStatus.AdditionalCharge = (e!)}}style={{ width: "95%",marginTop:5 }}/></Col>
                    <Col span={12}><InputNumber disabled={true || !((Status === "NoShow") || (Status === "Dropoff") || (Status === "Cancel")  || Status?.includes("Uploaded") )} defaultValue = {OrderDetailPageStatus.TunnelCharge!} onChange={(e)=>{OrderDetailPageStatus.TunnelCharge = (e!)}}style={{ width: "95%",marginTop:5 }}/></Col>
                </Row>
                </Col>
                <Col span={5} style={{background:"#ffffff",borderRadius:10,border:" 2px solid #1677ff",padding:5}}>
                    <Row style={{height:20,width:"100%",overflow:"hidden",overflowX:"hidden"}}>
                    <Col span={4}><Text>{"Count"}</Text></Col>
                    <Col span={5}><Text>{"Charge"}</Text></Col>
                    <Col span={15}><Text>{"Tunnel"}</Text></Col>
                    </Row>
                    <Row>
                    <div style={{height:80,width:"100%",overflow:"scroll",overflowX:"hidden"}}>
                        {tunnelUI()}
                    </div>
                    </Row>
                </Col>
                <Col span={5}>
                <Row style={{height:120,marginLeft:5,background:"#ffffff",borderRadius:10,border:" 2px solid #1677ff",padding:5}}>
                    <Col span={12}>
                    <Row><Text style={{marginTop:0}}>Start Wait Time</Text></Row>
                    <Row><Text style={{marginTop:5}}>Wait Duration</Text></Row>
                    <Row><Text style={{marginTop:5}}>Pick-up Time</Text></Row>
                    <Row><Text style={{marginTop:5}}>Drop-off Time</Text></Row>
                    </Col>
                    <Col span={12}>
                    {/* <Row><TimePicker size='small' disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || !(false || Status === "Dropoff" || Status === "Cancel" || Status === "Noshow")} 
                    defaultValue={dayjs(`${DriverStartWaitDatetime!.length >=16 ?DriverStartWaitDatetime!.substring(11,16):"00:00"}`,'HH:mm')} format="HH:mm" 
                        onChange={(e)=>{
                            if(e !== null){
                                //ConsoleLog(e)
                                //ConsoleLog(TolocalDateString(e?.toISOString()))
                                SetDriverStartWaitDatetime(TolocalDateString(e?.toISOString()).substring(0,16))
                                OrderDetailPageStatus.DriverStartWaitDatetime = (TolocalDateString(e?.toISOString()).substring(0,16))
                                saveOrderDetailPageStatus()
                            }
                        }
                    } style={{ width: "95%",marginTop:0 }}/></Row> */}
                    <Row>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimeField
                                disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || !(false || Status === "Dropoff" || Status === "Cancel" || Status === "Noshow")}                    
                                size='small'
                                inputProps={{style: { paddingTop:1,paddingBottom:1,paddingLeft:10,fontSize:15,textAlign: 'left' }}}
                                style={{marginTop:2,width:"98%"}}
                                value={dayjs(`${
                                    `${DriverStartWaitDatetime!.length >=16 ?DriverStartWaitDatetime!.substring(11,16):"00:00"}`
                                }`,
                                'HH:mm')}
                                onChange={
                                    (e)=>{
                                        ConsoleLog(`${e}`)
                                        if((`${e}` !== "Invalid Date") && (`${e}` !== "null")){
                                            SetDriverStartWaitDatetime(TolocalDateString(e?.toISOString()).substring(0,16))
                                            OrderDetailPageStatus.DriverStartWaitDatetime = (TolocalDateString(e?.toISOString()).substring(0,16))
                                            saveOrderDetailPageStatus()
                                        }
                                    }
                                }
                                format="HH:mm"
                                />
                        </LocalizationProvider>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <InputNumber size='small' controls={false} disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || !(false || Status === "Dropoff" || Status === "Cancel" || Status === "Noshow")} 
                            defaultValue = {(parseInt(DriverWaitDuration!) - parseInt(DriverWaitDuration!)%60)/60} 
                            onChange={(e)=>{
                                //ConsoleLog(parseInt(e))
                                if(e !== null){
                                    SetDriverWaitDurationM(e)
                                    saveOrderDetailPageStatus()
                                    //OrderDetailPageStatus.Dri
                                }else{
                                    SetDriverWaitDurationM(0)
                                    saveOrderDetailPageStatus()
                                }

                            }}
                            style={{ width: "95%",marginTop:2 }}/>
                        </Col>
                        <Col span={12} style={{marginTop:2}}><Text>Minutes</Text></Col>
                        <Col span={0}>
                            <InputNumber size='small' controls={false} disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || !(false || Status === "Dropoff" || Status === "Cancel" || Status === "Noshow")} 
                            defaultValue = {parseInt(DriverWaitDuration!)%60} 
                            onChange={(e)=>{
                                if(e !== null){
                                    //SetDriverWaitDurationS(e)
                                    //saveOrderDetailPageStatus()
                                }else{
                                    //SetDriverWaitDurationS(0)
                                    //saveOrderDetailPageStatus()
                                }
                            }}
                            style={{ width: "95%",marginTop:5 }}/>
                        </Col>
                        <Col span={0}><Text style={{marginTop:5}}>s</Text></Col>
                    </Row>
                    {/* <Row><TimePicker size='small' disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || !(false || Status === "Dropoff" || Status === "Cancel" || Status === "Noshow")} defaultValue={dayjs(`${DriverPickUpDatetime!.length >=16 ?DriverPickUpDatetime!.substring(11,16):"00:00"}`,'HH:mm')} format="HH:mm" 
                        onChange={(e)=>{
                            if(e !== null){
                                SetDriverPickUpDatetime(TolocalDateString(e?.toISOString()).substring(0,16))
                                OrderDetailPageStatus.DriverPickUpDatetime = (TolocalDateString(e?.toISOString()).substring(0,16))
                                saveOrderDetailPageStatus()
                            }
                        }
                    } style={{ width: "95%",marginTop:2 }}/></Row> */}
                    <Row>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimeField
                                disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || !(false || Status === "Dropoff" || Status === "Cancel" || Status === "Noshow")}                    
                                size='small'
                                inputProps={{style: { paddingTop:1,paddingBottom:1,paddingLeft:10,fontSize:15,textAlign: 'left' }}}
                                style={{marginTop:2,width:"98%"}}
                                value={dayjs(`${
                                    `${DriverPickUpDatetime!.length >=16 ?DriverPickUpDatetime!.substring(11,16):"00:00"}`
                                }`,
                                'HH:mm')}
                                onChange={
                                    (e)=>{
                                        ConsoleLog(`${e}`)
                                        if((`${e}` !== "Invalid Date") && (`${e}` !== "null")){
                                            SetDriverPickUpDatetime(TolocalDateString(e?.toISOString()).substring(0,16))
                                            OrderDetailPageStatus.DriverPickUpDatetime = (TolocalDateString(e?.toISOString()).substring(0,16))
                                            saveOrderDetailPageStatus()
                                        }
                                    }
                                }
                                format="HH:mm"
                                />
                        </LocalizationProvider>
                    </Row>
                    {/* <Row><TimePicker size='small' disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || !(false || Status === "Dropoff" || Status === "Cancel" || Status === "Noshow")} defaultValue={dayjs(`${DriverDropOffDatetime!.length >=16 ?DriverDropOffDatetime!.substring(11,16):"00:00"}`,'HH:mm')} format="HH:mm" 
                        onChange={(e)=>{
                            if(e !== null){
                                SetDriverDropOffDatetime(TolocalDateString(e?.toISOString()).substring(0,16))
                                OrderDetailPageStatus.DriverDropOffDatetime = (TolocalDateString(e?.toISOString()).substring(0,16))
                                saveOrderDetailPageStatus()
                            }
                        }
                    } style={{ width: "95%",marginTop:2 }}/></Row> */}
                    <Row>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimeField
                                disabled={OrderDetailPageStatus.ReadyToUpload === "1" || Status?.includes("Uploaded") || !(false || Status === "Dropoff" || Status === "Cancel" || Status === "Noshow")}                    
                                size='small'
                                inputProps={{style: { paddingTop:1,paddingBottom:1,paddingLeft:10,fontSize:15,textAlign: 'left' }}}
                                style={{marginTop:2,width:"98%"}}
                                value={dayjs(`${
                                    `${DriverDropOffDatetime!.length >=16 ?DriverDropOffDatetime!.substring(11,16):"00:00"}`
                                }`,
                                'HH:mm')}
                                onChange={
                                    (e)=>{
                                        ConsoleLog(`${e}`)
                                        if((`${e}` !== "Invalid Date") && (`${e}` !== "null")){
                                            SetDriverDropOffDatetime(TolocalDateString(e?.toISOString()).substring(0,16))
                                            OrderDetailPageStatus.DriverDropOffDatetime = (TolocalDateString(e?.toISOString()).substring(0,16))
                                            saveOrderDetailPageStatus()
                                        }
                                    }
                                }
                                format="HH:mm"
                                />
                        </LocalizationProvider>
                    </Row>
                    </Col>
                </Row>
                </Col>
            </Row>
    </div>)
    /*
                    <Row><Text style={{ width: "95%",textAlign:"right",marginTop:0}}>{DriverStartWaitDatetime!.length >=16 ?DriverStartWaitDatetime!.substring(11,16):""}</Text></Row>
                    <Row><Text style={{ width: "95%",textAlign:"right",marginTop:5}}>{waitTime()}</Text></Row>
                    <Row><Text style={{ width: "95%",textAlign:"right",marginTop:5}}>{DriverPickUpDatetime!.length >=16 ?DriverPickUpDatetime!.substring(11,16):""}</Text></Row>
                    <Row><Text style={{ width: "95%",textAlign:"right",marginTop:5}}>{DriverDropOffDatetime!.length >=16 ?DriverDropOffDatetime!.substring(11,16):""}</Text></Row>
    */
    const dialog_element09 = ()=>{return (<div>
            <Row style={{marginTop:5}}>
                <Col span={3}  style={{marginTop:5}}><Text>Current Order Status</Text></Col>
                <Col span={3}>
                    <Select style={{ width: "98%" }} defaultValue = {Status} onChange={(e)=>{SetStatus(e)}}>
                    <Select.Option value="Opened">Opened</Select.Option>
                    <Select.Option value="Dispatched">Dispatched</Select.Option>
                    <Select.Option value="Accepted">Accepted</Select.Option>
                    <Select.Option value="PickUp">Pick Up</Select.Option>
                    <Select.Option value="Waiting">Waiting</Select.Option>
                    <Select.Option value="NoShow">No Show</Select.Option>
                    <Select.Option value="Canceled">Canceled</Select.Option>
                    <Select.Option value="Compeleted">Compeleted</Select.Option>
                    </Select>
                </Col>
            </Row>
    </div>)}
    const dialog_element10 = ()=>{return (<div>
            <Row>
                <Col span={12}></Col>
                <Col span={4}>
                <Button type="primary" 
                    style={{width:"98%"}}
                    onClick={()=>{window.close()}}>
                    Cancel
                </Button>
                </Col>
                <Col span={4}>
                <Button type="primary" 
                    style={{width:"98%"}}
                    onClick={()=>{
                        confirmDialog("Duplicate Record?","",
                            ()=>{
                            //setConfirmLoading(true);
                            setTimeout(() => {
                                //ConsoleLog("save")
                                UpdateOrder(true,false);
                            }, 500);    
                            },
                            ()=>{
                            //setIsModalOpen1(false);
                        })
                    }}>
                    Duplicate
                </Button>
                </Col>
                <Col span={4}>
                <Button type="primary" 
                    style={{width:"100%"}}
                    onClick={()=>{
                        confirmDialog("Save Edit Record?","",
                            ()=>{
                            //setConfirmLoading(true);
                            setTimeout(() => {
                                //ConsoleLog("save")
                                UpdateOrder(false,false);
                            }, 500);    
                            },
                            ()=>{
                            //setIsModalOpen1(false);
                        })
                    }}>
                    Save
                </Button>
                </Col>
            </Row>
    </div>)}
    const dialog_element11 = (<div>
        <Row style={{paddingLeft:10,paddingRight:10,marginTop:5,height:2,background:"#1677ff"}}></Row>
        <Row>
            <Col span={18}>
                <Row>
                    <Col span={2}><Text>Created by:</Text></Col>
                    <Col span={6}><Text>{`${OrderDetailPageStatus.CreatedBy} [${OrderDetailPageStatus.CreatedDatetime}]`}</Text></Col>
                    <Col span={1}></Col>
                    <Col span={2}><Text>Updated by:</Text></Col>
                    <Col span={6}><Text>{`${OrderDetailPageStatus.UpdatedBy} [${OrderDetailPageStatus.UpdatedDatetime}]`}</Text></Col>
                </Row>
            </Col>
            <Col span={6}>
                <Button type="primary" disabled={(OrderDetailPageStatus.OrderNo! === "")} 
                    style={{background:"#77933C",marginTop:5,width:"98%"}}
                    onClick={()=>{
                        GetImage(OrderDetailPageStatus.OrderNo!,false)
                        localStorage.setItem('isOpenPhoto',"true")
                        setIsModalOpen2(true)
                    }}>
                        Supporting Documents
                </Button>
            </Col>
        </Row>
    </div>)

    const showBar = ()=>{
        if(Status === "Open"){
            return dialog_element00_02
        }else if(Status === "Dispatched"){
            return dialog_element00_03
        }else if(Status === "Acknowledge"){
            return dialog_element00_03
        }else if(Status === "Waiting"){
            return dialog_element00_03
        }else if(Status === "Pickup"){
            return dialog_element00_04
        }else if(Status === "Dropoff"){
            return dialog_element00_01
        }else if(Status === "Noshow"){
            return dialog_element00_01
        }else if(Status === "Cancel"){
            return dialog_element00_01
        }else if(Status?.includes("Upload")){
            return dialog_element00_01
        }else{
            return dialog_element00_02
        }
    }
    const dialog1 = 
        <div style={{ width: 1370 }}>
            <Row>
                <Col span={24}>
                {showBar()}
                {dialog_element01}
                {dialog_element02}
                {dialog_element03}
                {dialog_element04}
                {dialog_element05}
                {dialog_element06}
                {dialog_element07}
                {dialog_element08}
                {dialog_element11}
                </Col>
                <Col span={0}><Text>Order No.</Text></Col>
            </Row>
            {}
        </div>

    return (
        <div className='content' style={{padding:'0vh'}}>
        <div style={{background:'#1677ff',height:'98vh',paddingTop:'1vh',paddingLeft:'1vh',paddingRight:'1vh',paddingBottom:'1vh',minWidth:1370}}>
        <div style={{background:'#ffffff',height:'98vh',paddingTop:'0vh',paddingLeft:'1vh',paddingRight:'0vh',paddingBottom:'0vh'}}>
            <div style={{overflow:"hidden"}}>
                {/*<p style={{height:500,overflow:"scroll"}}> */}
                <Spin size="large" spinning={loading} tip="Loading...">
                <Space
                    direction="vertical"
                    style={{ width: "99%" }}
                >   
                    
                    <Space
                        direction="horizontal"
                    >
                        {dialog1}
                        {dialogAssignDriver}
                        {dialogPhoto}
                        {dialogAudit}
                    </Space>
                    
                </Space>
                </Spin>
                {/*</p>*/}
                </div>
            </div>
        </div>
        </div>
    );
}

export default App;

/*
    const [OrderNo,SetOrderNo] = useState(`${localStorage.getItem('DCHOrderNo')}`);
    const [BookingDatetime,SetBookingDatetime] = useState(localStorage.getItem('DCHBookingDatetime'));
    const [CarType,SetCarType] = useState(localStorage.getItem('DCHCarType'));
    const [Journey,SetJourney] = useState(localStorage.getItem('DCHJourney'));
    const [Title,SetTitle] = useState(localStorage.getItem('DCHTitle'));
    const [CustomerType,SetCustomerType] = useState(localStorage.getItem('DCHCustomerType'));
    const [CustomerName,SetCustomerName] = useState(localStorage.getItem('DCHCustomerName'));
    const [Address,SetAddress] = useState(localStorage.getItem('DCHAddress'));
    const [ContactName1,SetContactName1] = useState(localStorage.getItem('DCHContactName1'));
    const [ContactTelNo1,SetContactTelNo1] = useState(localStorage.getItem('DCHContactTelNo1'));
    const [PassengerName,SetPassengerName] = useState(localStorage.getItem('DCHPassengerName'));
    const [PassengerTelNo,SetPassengerTelNo] = useState(localStorage.getItem('DCHPassengerTelNo'));
    const [EstimatedOrderDuration,SetEstimatedOrderDuration] = useState(localStorage.getItem('DCHEstimatedOrderDuration'));
    const [Pickup,SetPickup] = useState(localStorage.getItem('DCHPickup'));
    const [Destination,SetDestination] = useState(localStorage.getItem('DCHDestination'));
    const [StopOver1,SetStopOver1] = useState(localStorage.getItem('DCHStopOver1'));
    const [StopOver2,SetStopOver2] = useState(localStorage.getItem('DCHStopOver2'));
    const [StopOver3,SetStopOver3] = useState(localStorage.getItem('DCHStopOver3'));
    const [FlightNo,SetFlightNo] = useState(localStorage.getItem('DCHFlightNo'));
    const [FlightStatus,SetFlightStatus] = useState(localStorage.getItem('DCHFlightStatus'));
    const [FlightSchedule,SetFlightSchedule] = useState(localStorage.getItem('DCHFlightSchedule'));
    const [NoOfPassenger,SetNoOfPassenger] = useState(`${parseInt(localStorage.getItem('DCHNoOfPassenger')!)}`);
    const [NoOfLuggage,SetNoOfLuggage] = useState(`${parseInt(localStorage.getItem('DCHNoOfLuggage')!)}`);
    const [HireRequest,SetHireRequest] = useState(localStorage.getItem('DCHHireRequest'));
    const [OrderCharge,SetOrderCharge] = useState(`${parseInt(localStorage.getItem('DCHOrderCharge')!)}`);
    const [AdditionalCharge,SetAdditionalCharge] = useState(`${parseInt(localStorage.getItem('DCHAdditionalCharge')!)}`);
    const [ParkingCharge,SetParkingCharge] = useState(`${parseInt(localStorage.getItem('DCHParkingCharge')!)}`);
    const [TunnelCharge,SetTunnelCharge] = useState(`${parseInt(localStorage.getItem('DCHTunnelCharge')!)}`);
    const [AssignVehicleID,SetAssignVehicleID] = useState(localStorage.getItem('DCHAssignVehicleID'));
    const [AssignDriverID,SetAssignDriverID] = useState(localStorage.getItem('DCHAssignDriverID'));
    const [Status,SetStatus] = useState(localStorage.getItem('DCHStatus'));
    const [Remarks,SetRemarks] = useState(localStorage.getItem('DCHRemarks'));
    const [VendorName,SetVendorName] = useState(localStorage.getItem('DCHVendorName'));
    const [VendorDriver,SetVendorDriver] = useState(localStorage.getItem('DCHVendorDriver'));
    const [HavePhoto,SetHavePhoto] = useState(localStorage.getItem('DCHHavePhoto'));
    const [HaveMeetNGreet,SetHaveMeetNGreet] = useState(localStorage.getItem('DCHHaveMeetNGreet'));
    const [ServiceProvider,SetServiceProvider] = useState(localStorage.getItem('DCHServiceProvider'));
    const [FirstReminder,SetFirstReminder] = useState(localStorage.getItem('DCHFirstReminder'));
    const [SpecialInstructions,SetSpecialInstructions] = useState(localStorage.getItem('DCHSpecialInstructions'));
    const [OrderPickUpDatetime,SetOrderPickUpDatetime] = useState(localStorage.getItem('DCHOrderPickUpDatetime'));
    const [OrderPickUpDate,SetOrderPickUpDate] = useState(localStorage.getItem('DCHOrderPickUpDatetime')!.length >=10 ? localStorage.getItem('DCHOrderPickUpDatetime')!.substring(0,10):TolocalDateString(new Date().toISOString()).substring(0,10));
    const [OrderPickUpTime,SetOrderPickUpTime] = useState(localStorage.getItem('DCHOrderPickUpDatetime')!.length >=16 ? localStorage.getItem('DCHOrderPickUpDatetime')!.substring(11,16):TolocalDateString(new Date().toISOString()).substring(10,16));
*/
